import React, { useState, useEffect } from "react";
import Modal from "react-responsive-modal";
import { exitButton } from "_shared/modal";
import BuildingInfo from "../../BuildingInfo/BuildingInfo";
import { Scrollbars } from "react-custom-scrollbars";

const BuildingModal = ({ 
  buildingData,
  isMedScreen, 
  setBuildingName,
   height, 
   width }) => {
  const [openModal, setOpenModal] = useState(false);

  const openModalClick = () => {
    setOpenModal(!openModal);
  };

  const closeModal = () => {
    setOpenModal(false);
  };

  const styling = {
    modal: {
      border: "1px solid #fafbfb",
      borderRadius: "8px",
      boxShadow: "0 0 20px rgba(241, 241, 242, 0.7)",
      height: "100vh",
      padding: 0,
      width: "100vw",
    },
    overlay: {
      background: "none", 
      overflow: "hidden",
    },
    closeButton: {
      color: "#e3e5e6",
    },
  };

  return (
    <div
      className={`flex w-auto cursor-pointer justify-between border border-solid border-[#fafbfb] bg-white md:w-[200px] lg:w-[300px] rounded-[4px] ${isMedScreen ? "h-fit" : "h-[34px]"}`}
      onClick={openModalClick}
    >
      <div 
        data-testid="mobile-buildinginfo-container"
        className={`ml-4 ${isMedScreen ? "h-11" : ""} flex items-center text-sm font-normal uppercase text-[#9da3a9]`}
      >
        Building Info
      </div>
      <i 
        className="fal fa-chevron-down fa-lg ml-2 mr-4 flex cursor-pointer items-center text-[#e3e5e6]"
        onClick={openModalClick}
      />
      <Modal
        styles={styling}
        open={openModal}
        onClose={closeModal}
        closeIconSvgPath={exitButton}
      >
        <Scrollbars
          autoHide
          autoHideDuration={200}
          universal
          className="flex h-[100dvh] flex-col overflow-x-hidden"
        >
          <BuildingInfo data={buildingData} height={height} width={width} setBuildingName={setBuildingName}/>
        </Scrollbars>
      </Modal>
    </div>
  );
};

export default BuildingModal;
