import { graphql } from "../../../../_shared/request";

const deleteFormAsset = async (formID, fileName) => {
  console.log("formID: ", formID);
  console.log("fileName: ", fileName);
  // console.log("TeamId: ", TeamId);
  const operationsDoc = `
      mutation MyMutation($attachment: String, $formId: bigint) {
        delete_form_assets(where: {attachment_name: {_eq: $attachment}, form_id: {_eq: $formId}}) {
          affected_rows
          returning {
            form_id
            attachment_name
            form {
              author
              authorName
            }
          }
        }
      }
    `;

  try {
    await graphql(operationsDoc, {
      attachment: fileName,
      formId: formID,
    }).then((response) => {
      console.log("response: ", response);
    });
  } catch (error) {
    console.error(error);
  }
};

const uploadFormAsset = async ({ formID, fileName }) => {
  console.log("formID: ", formID);
  // console.log("TeamId: ", TeamId);
  const operationsDoc = `
      mutation UploadFormAsset($attachment_name: String, $form_id: bigint) {
        insert_form_assets(objects: {attachment_name: $attachment_name, form_id: $form_id}, on_conflict: {constraint: form_assets_pkey, update_columns: updated_at}) {
          affected_rows
          returning {
            attachment_name
            created_at
            form_id
            updated_at
            form {
              author
              created_at
              form_type
              form_status
              id
              updated_at
            }
          }
        }
      }
    `;

  try {
    await graphql(operationsDoc, {
      attachment_name: fileName,
      form_id: formID,
    }).then((response) => {
      console.log("upload form asset response: ", response);
    });
  } catch (error) {
    console.error("upload form asset error: ", error);
  }
};

const updateForm = async (formData, recent_edits, formID, editor) => {
  console.log({ editor, recent_edits });
  async function getEditHistory() {
    //this func gets adds new edit data to old edit history
    const editHistoryQuery = `
      query EditHistory($formID: Int) {
        form_data(where: {form_id: {_eq: $formID}}) {
          edit_history
        }
      }
    `;

    try {
      let old_edit_history = await graphql(editHistoryQuery, {
        formID: formID,
      }).then((res) => {
        // console.log("edit history res: ", res.data.data["form_data"][0]["edit_history"]["edit_history_data"]);
        // console.log("edit history data: ", res.data.data["form_data"][0]);
        try {
          return res.data.data["form_data"][0]["edit_history"][
            "edit_history_data"
          ];
        } catch {
          //if res.data.data["form_data"][0]["edit_history"] is null
          return [];
        }
      });
      const historyList = old_edit_history;
      console.log({ old_edit_history, historyList });
      const editHistoryData = {
        edit_history_data: [...historyList, recent_edits],
        time_last_edited: recent_edits["time_of_edit"],
        last_edited_by: recent_edits["editor"],
      };
      console.log({ editHistoryData });
      return editHistoryData; //need to pass actual object later
    } catch (error) {
      console.log("edit history query error: ", error);
      return {
        edit_history_data: [],
        time_last_edited: "",
        last_edited_by: "",
      };
    }
  }

  const editHistoryData = await getEditHistory();
  console.log("editHistoryData after call: ", editHistoryData);

  const editFormMutation = `
      mutation UpdateForm($formID: Int, $data: jsonb = "", $last_edited_by: String = "", $edit_history: jsonb = "") {
        update_form_data(where: {form_id: {_eq: $formID}}, _set: {data: $data, last_edited_by: $last_edited_by, isEdited: true, edit_history: $edit_history}) {
          affected_rows
          returning {
            data
            edit_history
          }
        }
      }
    `;

  try {
    const updateFormStatus = await graphql(editFormMutation, {
      data: formData,
      formID: formID,
      last_edited_by: editor,
      edit_history: editHistoryData,
    }).then((response) => {
      console.log("update form response: ", response);
      return response;
    });
    return updateFormStatus;
  } catch (error) {
    console.log("error updating form: ", error);
    return new Promise((resolve) => {
      resolve("error updating form. . .");
    }); //false on failure
  }
};

const updateFormAssets = async (formID, editor, recent_edits) => {
  async function getEditHistory() {
    //this func gets adds new edit data to old edit history
    const editHistoryQuery = `
      query MyQuery($formID: Int) {
        form_data(where: {form_id: {_eq: $formID}}) {
          edit_history
        }
      }
    `;

    try {
      let old_edit_history = await graphql(editHistoryQuery, {
        formID: formID,
      }).then((res) => {
        // console.log("edit history res: ", res.data.data["form_data"][0]["edit_history"]["edit_history_data"]);
        // console.log("edit history data: ", res.data.data["form_data"][0]);
        return res.data.data["form_data"][0]["edit_history"][
          "edit_history_data"
        ]
          ? res.data.data["form_data"][0]["edit_history"]["edit_history_data"]
          : [];
      });
      const historyList = old_edit_history;
      console.log({ old_edit_history, historyList });
      const editHistoryData = {
        edit_history_data: [...historyList, recent_edits],
        time_last_edited: recent_edits["time_of_edit"],
        last_edited_by: recent_edits["editor"],
      };
      // console.log({ editHistoryData });
      return editHistoryData; //need to pass actual object later
    } catch (error) {
      console.log("edit history query error: ", error);
      return {
        edit_history_data: [],
        time_last_edited: "",
        last_edited_by: "",
      };
    }
  }

  const editHistoryData = await getEditHistory();

  const editHistoryDataMutation = `
    mutation MyMutation($formID: Int, $last_edited_by: String = "", $edit_history: jsonb = "") {
      update_form_data(where: {form_id: {_eq: $formID}}, _set: {last_edited_by: $last_edited_by, isEdited: true, edit_history: $edit_history}) {
        affected_rows
        returning {
          data
          edit_history
        }
      }
    }
  `;

  try {
    const response = await graphql(editHistoryDataMutation, {
      formID: formID,
      last_edited_by: editor,
      edit_history: editHistoryData,
    });
    return response;
  } catch (error) {
    console.log("error updating edit history for form asseets");
    return new Promise((resolve) => {
      resolve("error updating form. . .");
    });
  }
};

export { deleteFormAsset, uploadFormAsset, updateForm, updateFormAssets };
