import React, { useEffect, useState } from "react";
import getDayOfYear from "date-fns/getDayOfYear";
import getOptimizedSrc from "_shared/useOptimizedImage";
import shadow from "_shared/images/overlay-vignette.png";
import { useTranslation } from "react-i18next";

function getDayOfYearMod(max) {
  // update by monday
  return getDayOfYear(new Date()) % max;
}

const getImagesNames = () => {
  const r = require.context("_shared/images/backgrounds/", true);
  const importAll = (r) =>
    r.keys().map((file) => {
      return file.replace("./", "");
    });
  return importAll(r);
};

const images = getImagesNames();

async function getImage(templateName) {
  try {
    let template = await import("_shared/images/backgrounds/" + templateName);
    return template.default;
  } catch (err) {
    console.error(err);
    return new Error(err);
  }
}

const ImageProvider = ({ children, width, height }) => {
  const [imgsrc, setImgSrc] = useState("");
  const [imgCred, setImgCred] = useState("");
  const { t } = useTranslation();
  useEffect(() => {
    const fetchImage = async () => {
      const chosenImageName = images[getDayOfYearMod(images.length)];
      const photoCredit = chosenImageName
        .replace(".jpg", "")
        .replace(/\d/, "")
        .trim()
        .split(" ")
        .splice(-2)
        .join(" ");
      const img = await getImage(chosenImageName);
      setImgCred(photoCredit);
      setImgSrc(getOptimizedSrc(img, width).src);
    };
    fetchImage();
  }, [width]);
  return (
    <div
      data-cy="background-image"
      style={{
        backgroundImage: `url("${
          getOptimizedSrc(shadow, width).src
        }"), url("${imgsrc}")`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        height,
      }}
    >
      <div
        style={{
          color: "white",
          position: "absolute",
          bottom: 13,
          right: 43,
          fontSize: 13,
          display: "block",
        }}
      >
        {t("Photo By")}
        {` ${imgCred}`}
      </div>
      {children}
    </div>
  );
};

export default ImageProvider;
