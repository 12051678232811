import { decisionsGet } from "_shared/request";
import { user } from "_shared/auth";
import { getEnv } from "../../../../utils/constants";

export const getApprovals = () => {
  return decisionsGet({
    url: `${getEnv("DECISIONS_ENDPOINT")}/Primary/`,
    params: {
      FlowAlias: "PendingApprovalsGAR",
      Action: "api",
      outputtype: "JSON",
      RequesterTeamID: user.TeamId,
    },
  });
};
