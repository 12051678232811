import { normalize } from "normalizr";
import Schema from "../schema";
import { get, post, put, patch } from "../_shared/request";
import {
  FETCH_TASK,
  FETCH_TASKS,
  CREATE_TASK,
  UPDATE_TASK,
  UPDATE_TASK_STATUS,
} from "../actions/types";
import config from "../../config/config";
import { getEnv } from "../utils/constants";

// EFFECTS
export const API = {
  getTask: (taskId) => {
    return get({
      url: `${getEnv("ITROOTURL")}/Task/GetTask/${taskId}`,
    });
  },
  getTaskSummaries: (filters) => {
    return get({
      url: `${getEnv("ITROOTURL")}/Task/GetTaskSummaries?${filters}`,
      params: {
        pageNumber: 0,
      },
    });
  },
  addTask: (data) => {
    return post({
      url: `${getEnv("ITROOTURL")}/Task/AddTask`,
      data,
    });
  },
  updateTask: (data) => {
    return put({
      url: `${getEnv("ITROOTURL")}/Task/UpdateTask`,
      data,
    });
  },
  updateTaskStatus: ({ requestId, taskId, data }) => {
    return patch({
      url: `${getEnv(
        "ITROOTURL"
      )}/Task/UpdateTaskStatus/${requestId}/${taskId}`,
      data,
    });
  },
};

// ACTION CREATORS
export const fetchTaskSuccess = ({ data }) => {
  const { entities, result } = normalize(data, Schema.taskSchema);

  return {
    type: FETCH_TASK.SUCCESS,
    entities: entities.tasks,
    ids: [result],
  };
};

export const fetchTaskFailure = (err) => ({
  type: FETCH_TASK.FAILURE,
  err: err.message,
});

export const fetchTasksSuccess = ({ data }) => {
  const { entities, result } = normalize(data, Schema.TaskListSchema);

  return {
    type: FETCH_TASKS.SUCCESS,
    entities: entities.tasks,
    ids: result,
  };
};

export const fetchTasksFailure = (err) => ({
  type: FETCH_TASKS.FAILURE,
  err: err.message,
});

export const createTaskSuccess = ({ data }) => {
  const { entities, result } = normalize(data, Schema.taskSchema);

  return {
    type: CREATE_TASK.SUCCESS,
    entities: entities.tasks,
    ids: [result],
  };
};

export const createTaskFailure = (err) => {
  return {
    type: CREATE_TASK.FAILURE,
    err: err.message,
  };
};

export const updateTaskSuccess = async (editedTask) => {
  // HACKY workaround to get updated Task Data since bankend is unwilling to
  // provide updated data from Update endpoint
  try {
    const response = await get({
      url: `${getEnv("ITROOTURL")}/Task/GetTask/${editedTask.taskId}`,
    });

    const { entities, result } = normalize(response.data, Schema.taskSchema);

    return {
      type: UPDATE_TASK.SUCCESS,
      entities: entities.tasks,
      ids: [result],
    };
  } catch (err) {
    return {
      type: UPDATE_TASK.FAILURE,
      err: err.message,
    };
  }
};

export const updateTaskFailure = (err) => ({
  type: UPDATE_TASK.FAILURE,
  err: err.message,
});

export const updateTaskStatusSuccess = async ({ taskId }) => {
  // HACKY workaround to get updated Task Data since bankend is unwilling to
  // provide updated data from Update endpoint
  try {
    const response = await get({
      url: `${getEnv("ITROOTURL")}/Task/GetTask/${taskId}`,
    });

    const { entities, result } = normalize(response.data, Schema.taskSchema);

    return {
      type: UPDATE_TASK_STATUS.SUCCESS,
      entities: entities.tasks,
      ids: [result],
    };
  } catch (err) {
    return {
      type: UPDATE_TASK_STATUS.FAILURE,
      err: err.message,
    };
  }
};

export const updateTaskStatusFailure = (err) => ({
  type: UPDATE_TASK_STATUS.FAILURE,
  err: err.response,
});

// ASYNC ACTION CALLER
export const loadTask = (taskId) => ({
  isCached: () => false,
  request: { type: FETCH_TASK.REQUEST, payload: taskId },
});

export const loadTasks = (filters) => ({
  isCached: () => false,
  request: { type: FETCH_TASKS.REQUEST, payload: filters },
});

export const addTask = (newTask) => ({
  isCached: () => false,
  request: { type: CREATE_TASK.REQUEST, payload: newTask },
});

export const editTask = (editedTask) => ({
  isCached: () => false,
  request: { type: UPDATE_TASK.REQUEST, payload: editedTask },
});

export const editTaskStatus = (payload) => ({
  isCached: () => false,
  request: { type: UPDATE_TASK_STATUS.REQUEST, payload },
});

export default {
  loadTask,
  loadTasks,
  addTask,
  editTask,
  editTaskStatus,
};
