/* global COMMITID */
import defaultConstants from "../../config/config";
const LS_KEY_PREFIX = "myapplied";
const today = new Date().toLocaleDateString();
const TODAY_LS_KEY = `${LS_KEY_PREFIX}-constants-${COMMITID}-${today}`;
console.log("TODAY_LS_KEY: ", TODAY_LS_KEY, LS_KEY_PREFIX, COMMITID, today);
const { hostname } = window.location;

const QA_SITES = [
  "appliedqa1.z22.web.core.windows.net",
  "appliedqa2.z22.web.core.windows.net",
  "appliedqa3.z22.web.core.windows.net",
  "appliedqa4.z22.web.core.windows.net",
  "appliedqa5.z22.web.core.windows.net",
  "appliedqa6.z22.web.core.windows.net",
  "appliedqa7.z22.web.core.windows.net",
  "appliedqa8.z22.web.core.windows.net",
  "appliedqa9.z22.web.core.windows.net",
  //new containers on qa sub
  "applied1qa.z1.web.core.windows.net",
  "applied2qa.z1.web.core.windows.net",
  "applied3qa.z1.web.core.windows.net",
  "applied4qa.z1.web.core.windows.net",
  "applied5qa.z1.web.core.windows.net",
  "applied6qa.z1.web.core.windows.net",
  "applied7qa.z1.web.core.windows.net",
  "applied8qa.z1.web.core.windows.net",
  "applied9qa.z1.web.core.windows.net",
  "applied10qa.z1.web.core.windows.net",
  "applied11qa.z1.web.core.windows.net",
  "applied12qa.z1.web.core.windows.net",
  "applied13qa.z1.web.core.windows.net",
  "applied14qa.z1.web.core.windows.net",
  "applied15qa.z1.web.core.windows.net",
];
const POC_SITES = [
  "appliedpoc0.z22.web.core.windows.net",
  "appliedpoc1.z22.web.core.windows.net",
  "appliedpoc2.z22.web.core.windows.net",
  "appliedpoc3.z22.web.core.windows.net",
  "appliedpoc4.z22.web.core.windows.net",
  "appliedpoc5.z22.web.core.windows.net",
  "appliedpoc6.z22.web.core.windows.net",
  "appliedpoc7.z22.web.core.windows.net",
  "appliedpoc8.z22.web.core.windows.net",
  "appliedpoc9.z22.web.core.windows.net",
  //new containers on dev sub
  "applied1poc.z1.web.core.windows.net",
  "applied2poc.z1.web.core.windows.net",
  "applied3poc.z1.web.core.windows.net",
  "applied4poc.z1.web.core.windows.net",
  "applied5poc.z1.web.core.windows.net",
  "applied6poc.z1.web.core.windows.net",
  "applied7poc.z1.web.core.windows.net",
  "applied8poc.z1.web.core.windows.net",
  "applied9poc.z1.web.core.windows.net",
  "applied10poc.z1.web.core.windows.net",
  "applied11poc.z1.web.core.windows.net",
  "applied12poc.z1.web.core.windows.net",
  "applied13poc.z1.web.core.windows.net",
  "applied14poc.z1.web.core.windows.net",
  "applied15poc.z1.web.core.windows.net",
  "applied16poc.z1.web.core.windows.net",
  "applied17poc.z1.web.core.windows.net",
  "applied18poc.z1.web.core.windows.net",
];
const DEV_SITES = [
  "applieddev1.z22.web.core.windows.net",
  "applieddev2.z22.web.core.windows.net",
  "applieddev3.z22.web.core.windows.net",
  "applieddev4.z22.web.core.windows.net",
  "applieddev5.z22.web.core.windows.net",
  "applieddev6.z22.web.core.windows.net",
  "applieddev7.z22.web.core.windows.net",
  "applieddev8.z22.web.core.windows.net",
  "applieddev9.z22.web.core.windows.net",
  "applied10dev.z1.web.core.windows.net",
  "applied11dev.z1.web.core.windows.net",
  "applied12dev.z1.web.core.windows.net",
  "applied13dev.z1.web.core.windows.net",
  "applied14dev.z1.web.core.windows.net",
  "applied15dev.z1.web.core.windows.net",
];
const HOSTNAME_WHITE_LIST = [
  "localhost",
  // Add qa / development sites to allow endpoint changes
  ...QA_SITES,
  ...DEV_SITES,
  ...POC_SITES,
  "appliedprodtest.z22.web.core.windows.net",
];
// ****** HELPERS ****** //

export const isProdEnv = hostname === "my.appliedmedical.com";
export const isProdTestEnv =
  hostname === "appliedprodtest.z22.web.core.windows.net";
export const isQaEnv = QA_SITES.includes(hostname);
//  ?
export const isDevEnv =
  DEV_SITES.includes(hostname) || hostname === "localhost";

export const isPocEnv = POC_SITES.includes(hostname);

export const isArcQaEnv =
  hostname === "appliedqa14.z22.web.core.windows.net" ||
  hostname === "applied14qa.z1.web.core.windows.net";
export const isArcDevEnv =
  hostname === "appliedqa13.z22.web.core.windows.net" ||
  "localhost" ||
  hostname === "applied13qa.z1.web.core.windows.net";
export const isArcPoc2Env = hostname === "appliedqa12.z22.web.core.windows.net";

export const isArcPoc1Env = hostname === "appliedqa11.z22.web.core.windows.net";

export const serveMaintenancePageConstants = () => {
  if (isProdEnv)
    return {
      origin: "PROD",
      pageName: "ALL",
      id: 1,
    };

  if (isProdTestEnv)
    return {
      origin: "PLAYGROUND",
      pageName: "ALL_PLAYGROUND",
      id: 5,
    };

  if (isQaEnv)
    return {
      origin: "QA",
      pageName: "ALL_QA",
      id: 2,
    };

  if (isArcPoc1Env)
    return {
      origin: "POC1",
      pageName: "ALL_POC1",
      id: 3,
    };

  if (isArcPoc2Env)
    return {
      origin: "POC2",
      pageName: "ALL_POC2",
      id: 4,
    };

  if (isArcDevEnv)
    return {
      origin: "DEV",
      pageName: "ALL_DEV",
      id: 6,
    };

  return {
    origin: "DEV",
    pageName: "ALL_DEV",
    id: 6,
  };
};

const serveConstants = () => {
  const savedConstants = JSON.parse(localStorage.getItem(TODAY_LS_KEY));

  // Clean Up

  Object.keys(localStorage).forEach((key) => {
    if (key !== TODAY_LS_KEY && key.includes(LS_KEY_PREFIX)) {
      localStorage.removeItem(key);
    }
  });

  // On Production
  if (hostname === "my.appliedmedical.com") {
    const production = require("../../config/config.prod");
    return production;
  }

  // On Production Test

  if (hostname === "appliedprodtest.z22.web.core.windows.net") {
    const productionTest = require("../../config/config.prodtest");
    return productionTest;
  }

  // Has Local Storage
  if (savedConstants) return savedConstants;

  // On ARC POC1

  if (hostname === "appliedqa11.z22.web.core.windows.net") {
    const poc1 = require("../../config/config.poc11");
    localStorage.setItem(TODAY_LS_KEY, JSON.stringify(poc1));
    return poc1;
  }

  // On ARC POC2

  if (hostname === "appliedqa12.z22.web.core.windows.net") {
    const poc2 = require("../../config/config.poc12");
    localStorage.setItem(TODAY_LS_KEY, JSON.stringify(poc2));
    return poc2;
  }

  // On ARC DEV

  if (
    hostname === "appliedqa13.z22.web.core.windows.net" ||
    hostname === "applied13qa.z1.web.core.windows.net"
  ) {
    const dev = require("../../config/config.dev");
    localStorage.setItem(TODAY_LS_KEY, JSON.stringify(dev));
    return dev;
  }

  // On ARC QA

  if (
    hostname === "appliedqa14.z22.web.core.windows.net" ||
    hostname === "applied14qa.z1.web.core.windows.net"
  ) {
    const qa = require("../../config/config.test");
    localStorage.setItem(TODAY_LS_KEY, JSON.stringify(qa));
    return qa;
  }

  // On QA

  if (QA_SITES.includes(hostname)) {
    const qa = require("../../config/config.test");
    localStorage.setItem(TODAY_LS_KEY, JSON.stringify(qa));
    return qa;
  }

  // On POC
  if (POC_SITES.includes(hostname)) {
    console.log("POC hostname: ", hostname);
    const poc = require("../../config/config.dev");
    localStorage.setItem(TODAY_LS_KEY, JSON.stringify(poc));
    return poc;
  }

  // On Dev
  if (DEV_SITES.includes(hostname)) {
    const dev = require("../../config/config.dev");
    localStorage.setItem(TODAY_LS_KEY, JSON.stringify(dev));
    return dev;
  }

  // On Localhost
  if (hostname === "localhost") {
    console.log("in dev");
    const development = require("../../config/config.dev");
    localStorage.setItem(TODAY_LS_KEY, JSON.stringify(development));
    return development;
  }

  localStorage.setItem(TODAY_LS_KEY, JSON.stringify(defaultConstants));
  return defaultConstants;
};

const getEnv = (api) => {
  return serveConstants()[api];
};

const updateConstantEnv = (target, env) => {
  const savedConstants = JSON.parse(localStorage.getItem(TODAY_LS_KEY));
  const endpoints = require(`../../config/config.${env}`);
  savedConstants[target] = endpoints[target];
  localStorage.setItem(TODAY_LS_KEY, JSON.stringify(savedConstants));
};

const updateConstantVal = (target, value) => {
  const savedConstants = JSON.parse(localStorage.getItem(TODAY_LS_KEY));
  savedConstants[target] = value;
  localStorage.setItem(TODAY_LS_KEY, JSON.stringify(savedConstants));
};

const addCustomValues = (list) => {
  const savedConstants = JSON.parse(localStorage.getItem(TODAY_LS_KEY));
  const newSet = { ...savedConstants, ...list };

  localStorage.setItem(TODAY_LS_KEY, JSON.stringify(newSet));
};

const restoreDefault = () => {
  localStorage.setItem(TODAY_LS_KEY, JSON.stringify(defaultConstants));
};

const changeEnvironments = (env) => {
  const retrievedEndpoints = require(`../../config/config.${env}`);
  localStorage.setItem(TODAY_LS_KEY, JSON.stringify(retrievedEndpoints));
};

export {
  HOSTNAME_WHITE_LIST,
  serveConstants,
  getEnv,
  updateConstantEnv,
  updateConstantVal,
  addCustomValues,
  restoreDefault,
  changeEnvironments,
  hostname,
};
