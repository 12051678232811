import React, { createContext, useContext, useState } from "react";

const AddEventToCalendarContext = createContext();

export const useAddEventToCalendar = () => {
  return useContext(AddEventToCalendarContext);
};

export const AddEventToCalendarProvider = ({ children }) => {
  const [eventsAddedToCalendar, setEventsAddedToCalendar] = useState([]); // keeps the (newsroom card and slideout)/(announcement card and detail view) in sync in the client

  return (
    <AddEventToCalendarContext.Provider
      value={{
        eventsAddedToCalendar,
        setEventsAddedToCalendar,
      }}
    >
      {children}
    </AddEventToCalendarContext.Provider>
  );
};
