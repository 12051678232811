import React from "react";

let inputRef = React.createRef();

const SearchBox = ({
  handleSearch,
  handleSearchSubmit,
  searchPlaceHolder,
  withBackground,
  width = 300,
  Ecommerce,
  ...props
}) => {
  return (
    <form
      noValidate
      onSubmit={(...props) => {
        handleSearchSubmit(...props);
        inputRef.current.blur();
      }}
      className="relative"
    >
      <input
        ref={inputRef}
        style={{ width }}
        className={`${
          withBackground
            ? "placeholder:text-base-25"
            : "border-white placeholder:text-white focus:border-b"
        } box-border h-9 rounded-[50px] border-0 bg-base-90 px-8 py-2 font-default text-sm font-light leading-5 text-black outline-0 placeholder:tracking-[1.5px] focus:rounded-[50px] focus:border-0 focus:caret-secondary focus:outline-0 focus:[background-position:100%_center] custom-lg:h-11`}
        type="text"
        placeholder={searchPlaceHolder ? searchPlaceHolder : ""}
        onChange={handleSearch}
        {...props}
        data-cy="search-input"
      />
      <i
        data-cy="search-icon"
        className={`${
          withBackground ? "text-[#a3a9ac]" : "text-white"
        } fal fa-search absolute right-4 top-[7px] cursor-pointer text-base custom-lg:right-6 custom-lg:top-[10px] custom-lg:text-[18px]`}
        onClick={handleSearchSubmit}
      />
    </form>
  );
};

export default SearchBox;
