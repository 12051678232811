import { get } from "_shared/request";

/**
 * @description Fetches announcements and events within the past 30 days for a team member
 * @param {string} TeamId The ID of the team member to fetch data for
 * @returns {Promise<Array>} A promise that resolves to an array of announcements and events
 */
const getAnnouncementsAndEvents = async (TeamId) => {
  const fetchData = async () => {
    try {
      const eventResponse = await get({
        url: `/events/current-date-to-future-30-days/${TeamId}`,
      });
      const announcementResponse = await get({
        url: `/announcements/current-date-to-past-30-days/${TeamId}`,
      });
      return [...eventResponse.data, ...announcementResponse.data];
    } catch (error) {
      console.error("Error fetching announcements and events data:", error);
      throw error;
    }
  };

  // The following retry logic was used for when endpoint was unstable
  // let retries = 6;
  // let returnData = [];

  // while (retries > 0) {
  //   try {
  //     returnData = await fetchData();
  //     if (returnData.length > 0) {
  //       break;
  //     }
  //   } catch (error) {
  //     console.error("Error fetching announcements and events data:", error);
  //     throw error;
  //   }
  //   retries--;
  //   await sleep(1000 * (7 - retries)); // Exponential backoff
  // }

  // console.log("Announcements and events data fetched successfully.");
  // return returnData;

  console.log("Announcements and events data fetched successfully.");
  return await fetchData();
};

/**
 * @description Fetches announcement types and their subscription status for a team member
 * @param {string} TeamId The ID of the team member to fetch data for
 * @returns {Promise<string[]>} A promise that resolves to an array of announcement types
 */
export async function getAnnouncementSubscriptions(TeamId) {
  const fetchData = async () => {
    try {
      const subscription = await get({
        url: `/announcements/types/${TeamId}`,
      });
      if (subscription?.data) {
        return subscription.data;
      }
    } catch (error) {
      console.error("Error fetching announcement types:", error);
      throw error;
    }
  };
  console.log("Announcement types data fetched successfully.");
  return await fetchData();
}

export const MarkAsRead = async (cardID, teamID) => {
  try {
    //   setLoading(true);
    let dataURL = `/announcements/RemoveNewTag/${cardID}/${teamID}`;
    const { data } = await get({
      url: `${dataURL}`,
    }).then((res) => {
      console.log(`MarkAsRead card #${cardID} res: `, res);
      return res;
    });
    return data;
  } catch (e) {
    //   setLoading(false);
    console.log(e);
  }
};

export default getAnnouncementsAndEvents;
