import { useCallback } from "react";
import { useMutation } from "react-query";
import PORAPI from "../PORAPI";

export const usePORSubmitResponse = (config) => {
  const mutationFn = useCallback(
    async ({ toItems, toApprovals, toAttachments, ...restData }) => {
      return PORAPI.submitResponse({
        ...restData,
        toItems: toItems.results,
        toApprovals: toApprovals.results,
      });
    },
    []
  );

  return useMutation(mutationFn, config);
};
