import React from "react";
import { Routes, BrowserRouter, Route } from "react-router-dom";
import loadable from "@loadable/component";
import Loading from "../Loading/Loading";
import Login from "../LogInBox/LogInBox";
import {
  ToastProvider,
  DefaultToastContainer,
} from "react-toast-notifications";
import ImageProvider from "../ImageProvider/imageProvider";
import { MOBILE_MAX_WIDTH } from "../AppContainer/AppContainer";
import AuthorizedRoutes from "../AuthorizedRoutes/AuthorizedRoutes.jsx";
import { user } from "../../_shared/auth.js";

const config = {
  fallback: (
    <div className="flex h-screen w-screen items-center justify-center">
      <Loading centered />
    </div>
  ),
};

const ToastContainer = (props) => (
  <DefaultToastContainer
    className="toast-container"
    style={{ zIndex: 9999 }}
    {...props}
  />
);
const Podcast = loadable(
  () => import(/* webpackPrefetch: true */ "../Podcast/Podcast"),
  config
);

const ChooseToRecycleVideo = loadable(
  () => import(/* webpackPrefetch: true */ "../Videos/Video-ChooseToRecycle"),
  config
);

const LiveVideo = loadable(
  () => import(/* webpackPrefetch: true */ "../Videos/Video-Live"),
  config
);

const LiveUpdate = loadable(
  () => import(/* webpackPrefetch: true */ "../Videos/Video-live-update"),
  config
);

const DirectoryHome = loadable(
  () => import(/* webpackPrefetch: true */ "../DirectoryHome/DirectoryHome"),
  config
);

const SearchBox = loadable(
  () => import(/* webpackPrefetch: true */ "../Home/Home"),
  config
);
const Portals = loadable(
  () => import(/* webpackPrefetch: true */ "../Portals/Portals"),
  config
);
const Directory = loadable(
  () => import(/* webpackPrefetch: true */ "../DirectoryPage/DirectoryPage"),
  config
);

const Resources = loadable(
  () => import(/* webpackPrefetch: true */ "../Resources/Resources"),
  config
);
const Search = loadable(
  () => import(/* webpackPrefetch: true */ "../Search/Search"),
  config
);
const Grid = loadable(
  () => import(/* webpackPrefetch: true */ "../Grid/Grid.jsx"),
  config
);
const Discounts = loadable(() =>
  import(/* webpackPrefetch: true */ "../Discounts/Discounts.jsx")
);
const Events = loadable(
  () => import(/* webpackPrefetch: true */ "../Events/Events"),
  config
);
const ErrorPage = loadable(
  () => import(/* webpackPrefetch: true */ "../Error/Error"),
  config
);
const Announcements = loadable(
  () => import(/* webpackPrefetch: true */ "../Announcement/Announcement"),
  config
);
const ATSNewsLetter = loadable(
  () =>
    import(
      /* webpackPrefetch: true */ "../ATSNewsLetter/EditionOne/ATSNewsLetter"
    ),
  config
);

const ATSNewsLetterEditionThree = loadable(
  () =>
    import(
      /* webpackPrefetch: true */ "../ATSNewsLetter/EditionThree/ATSNewsLetterEditionThree"
    ),
  config
);

const ATSNewsLetterEditionTwo = loadable(
  () =>
    import(
      /* webpackPrefetch: true */ "../ATSNewsLetter/EditionTwo/ATSNewsLetterEditionTwo"
    ),
  config
);

const ATSAMENewsLetterEditionOne = loadable(
  () =>
    import(
      /* webpackPrefetch: true */ "../ATSAMENewsLetter/EditionOne/ATSNewsLetterEditionOne"
    ),
  config
);

const ATSAMENewsLetterEditionTwo = loadable(
  () =>
    import(
      /* webpackPrefetch: true */ "../ATSAMENewsLetter/EditionTwo/ATSNewsLetterEditionTwo"
    ),
  config
);

const CompanyOrgChart = loadable(
  () => import(/* webpackPrefetch: true */ "../OrgChart/OrganizationChart"),
  config
);
const CampusMaps = loadable(
  () => import(/* webpackPrefetch: true */ "../CampusMaps/CampusMaps"),
  config
);

const UserProfile = loadable(
  () => import(/* webpackPrefetch: true */ "../UserProfile/UserProfile"),
  config
);

const ECommerceApp = loadable(
  () => import(/* webpackPrefetch: true */ "../AppLauncher/eCommerce/App.jsx"),
  config
);

const Fivezeroonek = loadable(
  () => import(/* webpackPrefetch: true*/ "../Videos/Video-501k"),
  config
);

const Facemask = loadable(
  () => import(/* webpackPrefetch: true*/ "../Videos/Face-Mask"),
  config
);

const TrekToSanta = loadable(
  () => import(/* webpackPrefetch: true*/ "../Videos/TrekToSanta"),
  config
);

const HolidayVideo2020 = loadable(
  () => import(/* webpackPrefetch: true*/ "../Videos/HolidayVideo2020"),
  config
);

const LoginTraining = loadable(
  () => import(/* webpackPrefetch: true*/ "../Videos/LoginTraining"),
  config
);

const MessageFromSaid = loadable(
  () => import(/* webpackPrefetch: true*/ "../Videos/MessageFromSaid"),
  config
);

const ThreeDPrinting = loadable(
  () => import(/* webpackPrefetch: true*/ "../Videos/Video-3dprinting"),
  config
);

const TrainingTree = loadable(() =>
  import(/* webpackPrefetch: true*/ "../TrainingTree")
);

const StoreReceipt = loadable(() =>
  import(
    /* webpackPrefetch: true*/ "../AppLauncher/eCommerce/StoreReceipt/StoreReceipt"
  )
);

const Notifications = loadable(() =>
  import(
    /* webpackPrefetch: true*/ "../Notifications/Notifications"
  )
);

const Newsroom = loadable(() =>
  import(
    /* webpackPrefetch: true*/ "../Newsroom/Newsroom"
  )
);

const AppRouter = () => {
  // These variables are required as props for the ImageProvider component--cannot use useViewPort hook here--will cause rerenders every time browser window is resized
  const width = Math.min(document.documentElement.clientWidth, innerWidth || 0);
  const height = Math.max(
    document.documentElement.clientHeight,
    innerHeight || 0
  );
  const isMobile = width < MOBILE_MAX_WIDTH;
  const setCalculateViewport = () => {};

  return (
    <ToastProvider
      autoDismissTimeout={10000}
      placement="bottom-right"
      components={{ ToastContainer }}
    >
      <BrowserRouter>
        <ImageProvider {...{ width, height, isMobile, setCalculateViewport }}>
          <Routes>
            <Route element={<AuthorizedRoutes />}>
              <Route path="/" element={<SearchBox user={user} />} />
              <Route path="/notifications/*" element={<SearchBox user={user} />} />
              
              <Route path="/search" element={<Search user={user} />} />
              <Route
                path="/search/:searchTerm"
                element={<Search user={user} />}
              />
              <Route path="/dashboard/:id" element={<Grid user={user} />} />
              <Route
                path="/orgchart/:teamid"
                element={<CompanyOrgChart user={user} />}
              />
              <Route
                path="/directory"
                element={<DirectoryHome user={user} />}
              />
              <Route
                path="/directory/:searchTerm/:id"
                // activeStyle={{ color: "red" }}
                element={<Directory user={user} />}
              />
              <Route
                path="/directory/:searchTerm/:id/dr"
                element={<Directory user={user} />}
              />
              <Route
                path="/discounts/:category"
                element={<Discounts user={user} />}
              />
              <Route
                path="/discounts/search/:search"
                element={<Discounts user={user} />}
              />
              <Route path="/events" element={<Events user={user} />} />
              <Route
                path="/announcements"
                element={<Announcements user={user} />}
              />
              <Route
                path="/announcements/:id"
                element={<Announcements user={user} />}
              />
              <Route
                path="/announcements/search/:search"
                element={<Announcements user={user} />}
              />
              <Route
                path="/announcements/category/:category"
                element={<Announcements user={user} />}
              />
              <Route path="/portals" element={<Portals user={user} />} />
              <Route
                path="/portals/:category"
                element={<Portals user={user} />}
              />
              <Route
                path="/portals/search/:search"
                element={<Portals user={user} />}
              />
              <Route path="/resources" element={<Resources user={user} />} />
              <Route
                path="/resources/:category/:edgecase"
                element={<Resources user={user} />}
              />
              <Route
                path="/resources/:category"
                element={<Resources user={user} />}
              />
              <Route
                path="/resources/search/:search"
                element={<Resources user={user} />}
              />
              <Route
                path="/resources/reports/:ReportID"
                element={<Resources user={user} />}
              />
              <Route
                path="/campusmap/:city"
                element={<CampusMaps user={user} />}
              />
              <Route
                path="/campusmap/:city/buildings"
                element={<CampusMaps user={user} />}
              />
              <Route
                path="/campusmap/:city/fullmap"
                element={<CampusMaps user={user} />}
              />
              <Route path="/profile" element={<UserProfile user={user} />} />
              <Route
                path="/applied-shop/receipt/:paymentId"
                element={<StoreReceipt user={user} />}
              />
              <Route
                path="/applied-shop/*"
                element={<ECommerceApp user={user} />}
              />
              <Route
                path="/ChooseToRecycle"
                element={<ChooseToRecycleVideo user={user} />}
              />
              <Route path="/live" element={<LiveVideo user={user} />} />
              <Route path="/live-update" element={<LiveUpdate user={user} />} />
              <Route path="/510k" element={<Fivezeroonek user={user} />} />
              <Route
                path="/oneteamoneheart"
                element={<Facemask user={user} />}
              />
              <Route
                path="/3dprinting"
                element={<ThreeDPrinting user={user} />}
              />
              <Route
                path="/training-tree/:moduleId/*"
                element={<TrainingTree user={user} />}
              />
              <Route
                path="/atsnewsletter/editionOne"
                element={<ATSNewsLetter user={user} />}
              />
              <Route
                path="/atsnewsletter/EditionThree"
                element={<ATSNewsLetterEditionThree user={user} />}
              />
              <Route
                path="/atsnewsletter/editionTwo"
                element={<ATSNewsLetterEditionTwo user={user} />}
              />
              <Route
                path="/atsamenewsletter/editionOne"
                element={<ATSAMENewsLetterEditionOne user={user} />}
              />
              <Route
                path="/atsamenewsletter/editionTwo"
                element={<ATSAMENewsLetterEditionTwo user={user} />}
              />
              <Route path="/podcast" element={<Podcast user={user} />} />
              <Route path="/Newsroom" element={<SearchBox user={user} />} />
            </Route>

            <Route path="/appliedoneteamoneheart" element={<Facemask />} />
            <Route path="/logintraining" element={<LoginTraining />} />
            <Route path="/trektosanta" element={<TrekToSanta />} />
            <Route path="/holidayvideo2020" element={<HolidayVideo2020 />} />
            <Route path="/MessageFromSaid" element={<MessageFromSaid />} />
            <Route path="/login" element={<Login />} />
            <Route path="/jwtlogin" element={<Login jwt />} />

            <Route path="*" element={<ErrorPage />} />
          </Routes>
        </ImageProvider>
      </BrowserRouter>
    </ToastProvider>
  );
};

export default AppRouter;
