import createActionSet from "../_shared/createActionSet";

export const FETCH_TASK = createActionSet("FETCH_TASK");
export const FETCH_TASKS = createActionSet("FETCH_TASKS");
export const CREATE_TASK = createActionSet("CREATE_TASK");
export const UPDATE_TASK = createActionSet("UPDATE_TASK");
export const UPDATE_TASK_STATUS = createActionSet("UPDATE_TASK_STATUS");

// HOOKS
export const USE_ASYNC = createActionSet("USE_ASYNC");
export const USE_MUTATION = createActionSet("USE_MUTATION");
