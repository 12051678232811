import React from "react";

export const exitButton = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24.004"
    viewBox="0 0 22 22.004"
  >
    <path
      id="letter-x"
      d="M.241,1.419a.847.847,0,0,1,0-1.179.847.847,0,0,1,1.179,0L8.5,7.329,15.587.239a.83.83,0,0,1,1.168,1.179L9.677,8.5l7.077,7.089a.83.83,0,0,1-1.168,1.179L8.5,9.676,1.421,16.765a.847.847,0,0,1-1.179,0,.847.847,0,0,1,0-1.179L7.319,8.5Z"
      transform="translate(-0.002 0)"
      fill="#bec2c5"
    />
  </svg>
);
