const optimalImgSrc = (img, width) =>
  img.images.reduce(
    (acc, val) => {
      const currentDiff = val.width - width;
      if (!acc.src) {
        return { src: val.path, diff: currentDiff };
      }
      if (acc.diff <= currentDiff && currentDiff >= 1000) {
        return acc;
      }
      return { src: val.path, diff: currentDiff };
    },
    { src: "", diff: 0 }
  );

export default optimalImgSrc;
