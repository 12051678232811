import { format } from "date-fns";

// Strings

export const formatAttachmentTitle = (title) => {
  title = title.split(".");
  const { 0: docName, 1: extension } = title;
  if (docName.length > 15) {
    return docName.substr(0, 14) + "...";
  }
  return `${docName}.${extension}`;
};

export const getPORType = (type) => {
  return type === "IPR" ? "IPOR" : type;
};

export const sumTotalPriceProcurement = (items) => {
  let total = items.reduce((acc, val) => {
    return acc + parseFloat(val.purchaseNetPrice);
  }, 0);

  return total.toFixed(4);
};

export const sumTotalPrice = (items) => {
  let total = items.reduce((acc, val) => {
    return acc + parseFloat(val.netPrice);
  }, 0);

  return total.toFixed(2);
};

export const sumTotalPricePOR = (items) => {
  let total = items.reduce((acc, val) => {
    const isReturn = val.returnsItemFlag === "X";
    if (val.actualUnitPrice) {
      const result = isReturn
        ? acc + parseFloat(val.netPrice)
        : acc + parseFloat(val.actualUnitPrice) * parseFloat(val.poQuantity);
      return result;
    } else {
      const result = isReturn
        ? acc + parseFloat(val.netPrice)
        : acc + parseFloat(val.estimatedUnitPrice) * parseFloat(val.poQuantity);
      return result;
    }
  }, 0);

  return total.toFixed(2);
};

export const displayOrderTitle = (item, porType) => {
  const isIPR = porType === "IPR";
  const price = isIPR
    ? sumTotalPrice(item.toItems.results)
    : sumTotalPricePOR(item.toItems.results);

  return `${getPORType(item.porType)}# ${item.porNum} - ${
    item.vendorName
  } - ${price} ${item.currency}`;
};

export const removeZero = (str) => {
  return str.replace(/(^0*)/, "");
};

export const totalQuantityPlanning = (items) => {
  let total = items.reduce((acc, val) => {
    return acc + parseFloat(val.poQuantity);
  }, 0);

  return total.toFixed(2);
};

export const totalQuantityProcurement = (items) => {
  let total = items.reduce((acc, val) => {
    return acc + parseFloat(val.purchasePOQuantity);
  }, 0);

  return total.toFixed(2);
};

export const withCurrency = (val) => {
  if (parseFloat(val).toFixed(2) === 0.0) return "$0";
  return val >= 0 ? `$${val}` : `-$${val * -1}`;
};

// Objects

export const formatTimeStamp = (time) => {
  // time = parseInt(time);
  return format(time, "MMMM d, yyyy");
};

export const cleanApprovers = (approvers) => {
  const approverNames = [];

  return approvers
    .filter((el) => {
      if (!el.approverName) return false;
      if (approverNames.indexOf(el.approverName) === -1) {
        approverNames.push(el.approverName);
        return true;
      }
      return false;
    })
    .map((el, idx) => {
      // Check if it's their turn (isTurn)
      if (el.Decision) {
        el.isTurn = false;
      }

      if (!el.Decision) {
        const lastPerson = approvers[idx - 1];
        const nextPerson = approvers[idx + 1];

        // Check if there's someone before them

        if (!lastPerson) {
          el.isTurn = true;
        }

        // Check if person before made decision and next person has not made a decision

        if (
          lastPerson &&
          lastPerson.decision &&
          nextPerson &&
          !nextPerson.decision
        ) {
          el.isTurn = true;
        }

        // If last approver, check if last person has made a decision

        if (!nextPerson && lastPerson && lastPerson.decision) {
          el.isTurn = true;
        }
      }

      return el;
    });
};
