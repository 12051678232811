import { useCallback, useEffect } from "react";
import { useQuery } from "react-query";
import { graphql } from "../../../../_shared/request";
import PCFAPI from ".";

export function getTotalPcfByRegion(isAMR, isAME, isAML, isAuNz) {
  const total = getTotal(isAMR, isAME, isAML, isAuNz).then((result) => {
    // console.log('result in getTotalPcfByRegion: ', result);
    return result;
  });
  // console.log("total after fetch: ", total);
  return total;
}

export function getPcfByRegion(isAMR, isAME, isAML, isAuNz) {
  const forms = getFilteredForms(isAMR, isAME, isAML, isAuNz).then((result) => {
    // console.log("forms in getPcfByRegion: ", result);
    return result;
  });
  // console.log("forms after fetch: ", forms);
  return forms;
}

export function getPcfByUser(authorID) {
  const userForms = getUserForms(authorID).then((result) => {
    return result;
  });
  // console.log({ userForms });
  return userForms;
}

// export function getProcessedPCF(isAMR, isAME, isAML, isAuNz) {
//   const forms = getProcessedForms(isAMR, isAME, isAML, isAuNz).then(
//     (result) => {
//       // console.log("forms in getProcessedPCF: ", result);
//       return result;
//     }
//   );
//   return forms;
// }

/**
 * Function to determine what region's forms the cs team view will display
 * @returns region of cs team member
 */
const selectCSregion = (isAMR, isAME, isAML, isAuNz) => {
  // console.log('regions in Select CS Region: ', {isAMR, isAME, isAML, isAuNz});
  if (isAME) {
    return "EU";
  } else if (isAML) {
    return "ME";
  } else if (isAuNz) {
    return "AUS";
  } else if (isAMR) {
    return "US";
  } else {
    return "N/A";
  }
};

async function getTotal(isAMR, isAME, isAML, isAuNz) {
  let total = await fetchCsPcf(isAMR, isAME, isAML, isAuNz).then((result) => {
    console.log("result length in getTotal: ", result.length);
    return result.length;
  });
  // console.log('total in getTotal: ', total);
  return total;
}

async function getFilteredForms(isAMR, isAME, isAML, isAuNz) {
  let filteredForms = await fetchCsPcf(isAMR, isAME, isAML, isAuNz).then(
    (result) => {
      return result;
    }
  );
  // console.log({ filteredForms });
  return filteredForms;
}

async function getUserForms(authorID) {
  const userForms = await fetchUserPcf(authorID).then((result) => {
    // console.log("result in getUserForms: ", result);
    return result;
  });
  return userForms;
}

export async function getProcessedForms(isAMR, isAME, isAML, isAuNz) {
  const processedForms = await fetchProcessedFormsByRegion(
    isAMR,
    isAME,
    isAML,
    isAuNz
  ).then((result) => {
    // console.log("result in getProcessedForms: ", result);
    return result;
  });
  return processedForms;
}

async function fetchProcessedFormsByRegion(isAMR, isAME, isAML, isAuNz) {
  const origin = global.location.origin;
  const operationsDoc = `
    query FetchProcessedForms($origin: String = "") {
      form(where: {isDirty: {_eq: false}, form_status: {_eq: "Processed"}, submission_origin: {_eq: $origin}}, order_by: {created_at: desc_nulls_last}) {
        author
        authorName
        created_at
        form_status
        form_type
        id
        updated_at
        title
        isDirty
        submission_origin
        form_data(order_by: {id: desc_nulls_last}, limit: 1) {
          id
          form_id
          data
        }
        eQMS_complaint_number
      }
    }
  `;
  try {
    const ProcessedForms = await graphql(operationsDoc, {
      origin: origin,
    }).then((result) => {
      const forms = result.data.data.form.filter((form) => {
        // console.log({form});
        // console.log('region: ' , form.form_data[0].data['Product Support'][0].value?.label);
        const userRegion = selectCSregion(isAMR, isAME, isAML, isAuNz);
        const formRegion = form.form_data[0].data["Product Support"][0].value
          .label
          ? form.form_data[0].data["Product Support"][0].value?.label ===
              "NZ" ||
            form.form_data[0].data["Product Support"][0].value?.label === "AUS"
            ? "AUS"
            : form.form_data[0].data["Product Support"][0].value?.label
          : form.form_data[0].data["Product Support"][0].value === "NZ" ||
            form.form_data[0].data["Product Support"][0].value === "AUS"
          ? "AUS"
          : form.form_data[0].data["Product Support"][0].value;
        // console.log({userRegion, formRegion});
        if (formRegion !== "undefined" && userRegion === formRegion) {
          //region field
          // console.log({userRegion,formRegion, 'filter': 'true', 'title': form.title, 'last updated': form.updated_at});
          return true;
        } else {
          // console.log({userRegion,formRegion, 'filter': 'false', 'title': form.title, 'last updated': form.updated_at});
          return false;
        }
      });
      return forms;
    });
    // console.log({ ProcessedForms });
    return ProcessedForms;
  } catch (error) {
    console.log("processed forms fetch error: ", error);
    return [];
  }
}

async function fetchCsPcf(isAMR, isAME, isAML, isAuNz) {
  // let totalPcf = 0;
  const submissionOrigin = global.location.origin;
  const cutoffDate = "2022-05-02T09:54:06.521878+00:00";
  // console.log({ cutoffDate });
  const operationsDoc = `
  query FetchCsPcf($_gte: timestamptz = "", $origin: String = "") {
    form(where: {isDirty: {_eq: false}, form_status: {_eq: "Submitted"}, created_at: {_gte: $_gte}, submission_origin: {_eq: $origin}}, order_by: {created_at: desc_nulls_last}) {
      author
      authorName
      created_at
      form_status
      form_type
      id
      updated_at
      title
      isDirty
      submission_origin
      form_data(order_by: {id: desc_nulls_last}, limit: 1) {
        id
        form_id
        data
      }
    }
  }
  `;
  try {
    let FilteredCsTeamForms = await graphql(operationsDoc, {
      _gte: cutoffDate, //cutoff date
      origin: submissionOrigin,
    }).then((response) => {
      const FilteredForms = response.data.data.form.filter((form) => {
        // console.log({form});
        // console.log('region: ' , form.form_data[0].data['Product Support'][0].value?.label);
        const userRegion = selectCSregion(isAMR, isAME, isAML, isAuNz);
        const formRegion = form.form_data[0].data["Product Support"][0].value
          .label
          ? form.form_data[0].data["Product Support"][0].value?.label ===
              "NZ" ||
            form.form_data[0].data["Product Support"][0].value?.label === "AUS"
            ? "AUS"
            : form.form_data[0].data["Product Support"][0].value?.label
          : form.form_data[0].data["Product Support"][0].value === "NZ" ||
            form.form_data[0].data["Product Support"][0].value === "AUS"
          ? "AUS"
          : form.form_data[0].data["Product Support"][0].value;

        // console.log({
        //   userRegion,
        //   formRegion,
        //   productSupport: form.form_data[0].data["Product Support"],
        // });
        // console.log({
        //   userRegion,
        //   formRegion,
        //   filter: "true",
        //   title: form.title,
        //   "last updated": form.updated_at,
        // });
        if (formRegion !== "undefined" && userRegion === formRegion) {
          //region field
          // console.log({userRegion,formRegion, 'filter': 'true', 'title': form.title, 'last updated': form.updated_at});
          return true;
        } else {
          // console.log({userRegion,formRegion, 'filter': 'false', 'title': form.title, 'last updated': form.updated_at});
          return false;
        }
      });
      return FilteredForms;
    });
    return FilteredCsTeamForms;
  } catch (error) {
    console.log("error fetching pcf total: ", error);
  }
}

async function fetchUserPcf(authorID) {
  const origin = global.location.origin;
  const operationsDoc = `
    query FetchUserPcf($authorID: Int, $origin: String = "") {
      form(where: {author: {_eq: $authorID}, isDirty: {_eq: false}, submission_origin: {_eq: $origin}}, order_by: {created_at: desc_nulls_last}) {
        author
        created_at
        form_status
        form_type
        id
        updated_at
        title
        isDirty
        submission_origin
        eQMS_complaint_number
      }
    }
  `;
  try {
    const response = await graphql(operationsDoc, { authorID, origin });
    const pcfData = response.data.data.form.map((el, index) => {
      return el;
    });
    // console.log("pcfData: ", pcfData);
    return pcfData;
  } catch (error) {
    console.log(error);
    return [];
  }
}

// //function to obtain SAS and URI
// export async function getContainerStats({ formID, TeamId }){
//   console.log("formID: ", formID);
//   console.log("TeamId: ", TeamId);
//   const operationsDoc = `
//     query MyQuery($author: Int, $id: Int) {
//       form(where: {author: {_eq: $author}, id: {_eq: $id}}) {
//         containerStats {
//           containerSAS
//           containerUri
//         }
//       }
//     }
//   `;

//   try {
//     return await graphql(operationsDoc, {
//       author: TeamId,
//       id: formID, //need to hard code form ID here
//     });
//     // console.log("response: ", response);
//   } catch (error) {
//     console.error(error);
//   }
// };

//used to query container stats for attachment file
export async function getContainerStats(formID) {
  console.log("type of formID: ", typeof formID);
  const operationsDoc = `
      query GetContainerStats($id: Int) {
        form(where: {id: {_eq: $id}}) {
          containerStats {
            containerSAS
            containerUri
          }
        }
      }
    `;

  try {
    return await graphql(operationsDoc, {
      id: formID,
    }).then((response) => {
      // console.log("get container stats response: ", response);
      // console.log(
      //   "response container stats: ",
      //   response.data.data.form[0]["containerStats"]
      // );
      // console.log('container stats: ', response.data.data['form_assets'][0].form['containerStats']);
      const SAS = response.data.data.form[0]["containerStats"]["containerSAS"];
      const URI = response.data.data.form[0]["containerStats"]["containerUri"];
      // console.log({ SAS, URI });
      return { SAS, URI };
    });
  } catch (error) {
    console.log("getContainerStats error: ", error);
  }
}

export async function getCountryByRegion(region) {
  const operationsDoc = `
    query GetCountryByRegion($region: String = "") {
      country_region(where: {region: {_eq: $region}}) {
        country
        countryCode
        region
      }
    }
  `;

  try {
    return await graphql(operationsDoc, {
      region: region,
    }).then((res) => {
      // console.log("country region res: ", res);
      return res;
    });
  } catch (error) {
    console.log("country by region error: ", error);
  }
}

export async function getHospitalDistributor(hospitalName) {
  const operationsDoc = `
    query GetHospitalDistributor($hospitalname: String = "") {
      search_am_cust_new(args: {hospitalname: $hospitalname}, limit: 100) {
        Email
        address
        city
        custnum
        custnum_old
        zipcode
        state
        name
      }
    }
  `;

  try {
    const response = await graphql(operationsDoc, {
      hospitalname: hospitalName,
    }).then((res) => {
      // console.log("hospitals: ", res);
      return res;
    });
    console.log("getHospitalDistributor: ", response);
    return response;
  } catch (error) {
    console.log("getHospitalDistributor error: ", error);
    return [];
  }
}

/**
 * for PCF
 */
export async function getProductFamily(productFamily) {
  const operationsDoc = `
    query GetProductFamily($productFamily: String = "") {
      product_family(where: {family_name: {_ilike: $productFamily}}) {
        family_name
        id
      }
    }
  `;

  try {
    const response = graphql(operationsDoc, {
      productFamily: productFamily,
    }).then((res) => {
      console.log("product family results: ", res.data.data["product_family"]);
      return res.data.data["product_family"];
    });
  } catch (error) {
    console.log("getProductFamily error: ", error);
    return [];
  }
}

export async function getEditHistory(formID) {
  const operationsDoc = `
    query GetEditHistory($formID: Int) {
      form_data(where: {form_id: {_eq: $formID}}) {
        edit_history
      }
    }
  `;
  try {
    const editHistoryData = await graphql(operationsDoc, {
      formID: formID,
    }).then((res) => {
      console.log("editHistoryResponse: ", res);
      return res.data.data["form_data"][0];
    });
    return editHistoryData;
  } catch (error) {
    console.log("getEditHistory error: ", error);
    return [];
  }
}
