import { useState } from "react";
import { useQuery, queryCache } from "react-query";
import { useAsync } from "@applied-med/ui/dist/hooks";
import { convertToDate } from "../POR.helpers";
import { getPORType } from "../../helpers/PORItem.helper";
import { msalJwtToken } from "_shared/auth";
import PORAPI from "../PORAPI";

export const useHasPORAccess = () => {
  const queryFn = async () => !!(await PORAPI.getApprovals()).data;

  const isEnabled =
    !!msalJwtToken &&
    (!queryCache.getQuery("por-has-access") ||
      queryCache.getQuery("por-has-access")?.state?.failureCount < 1);

  return useQuery("por-has-access", queryFn, {
    staleTime: 1000 * 60 * 30,
    retry: 0,
    isEnabled,
    refetchOnMount: isEnabled,
    refetchOnWindowFocus: isEnabled,
  });
};

export const useApprovals = (config = {}) => {
  const queryFn = async () => {
    const response = await PORAPI.getApprovals()
      .then((res) => {
        // console.log("por queries res: ", res);
        return res;
      })
      .catch((error) => {
        console.log(error);
        return "cannot access POR";
        // return error
      });
    if (response === "cannot access POR") {
      console.log("returning blank items and desktoplink. . .");
      return { items: [], desktopLink: "zpor:zpor?env=ZGW_PRD" };
    }

    const approvalsData = response.data;
    console.log({ approvalsData });
    // const items = approvalsData.tasks.results
    const items = approvalsData.results
      .filter(({ supportsClaim, supportsRelease }) => {
        return supportsClaim || supportsRelease;
      })
      .sort((el) => (el.priority === "VERYHIGH" ? -1 : 1));

    return {
      items,
      desktopLink: "zpor:zpor?env=ZGW_PRD",
    };
  };

  return useQuery("por-approvals", queryFn, {
    staleTime: 1000 * 15,
    retry: 1,
    onError: (error) => {
      console.log("react query error: ", error);
    },
    // retryOnMount: false,
    ...config,
  });
};

export const useMyRequests = (config = {}) => {
  const queryFn = async () => {
    return (
      await Promise.all([
        PORAPI.getRequests({ params: { type: "POR", version: "70" } }),
        PORAPI.getRequests({ params: { type: "IPR", version: "70" } }),
      ])
    )
      .reduce((acc, { data: { results } }) => [...acc, ...results], [])
      .sort(
        (a, b) =>
          new Date(convertToDate(b.submittedDate)) -
          new Date(convertToDate(a.submittedDate))
      )
      .map((el) => ({
        ...el,
        text: `${getPORType(el.porType)}# ${el.porNum} - ${el.vendorName}`,
      }))
      .filter((el) => el.status !== "DRAFT");
  };

  return useQuery("por-my-requests", queryFn, {
    staleTime: 1000 * 30,
    retry: 1,
    ...config,
  });
};

export const useApprovalDetails = ({ args, ...config }) => {
  const queryFn = async () => (await PORAPI.getApprovalDetails(args)).data;

  return useQuery(["por-approval", args], queryFn, {
    staleTime: 1000 * 15,
    retry: 1,
    ...config,
  });
};

export const useRequestDetails = ({ args, ...config }) => {
  const queryFn = async () => {
    const request = (await PORAPI.getRequestDetails(args)).data;

    // const attachments = await Promise.all(
    //   request.toAttachments.results.map((el) =>
    //     PORAPI.getAttachmentDetails({
    //       params: { pornumber: el.porNum, seqnumber: el.seq },
    //     })
    //   )
    // );

    return {
      ...request,
      // toAttachments: {
      //   results: attachments.map((el, idx) => ({
      //     ...request.toAttachments.results[idx],
      //     ...el.data,
      //   })),
      // },
    };
  };

  return useQuery(["por-my-request", args], queryFn, {
    staleTime: 1000 * 15,
    retry: 1,
    ...config,
  });
};

export const usePORDetails = ({ args }) => {
  console.log("args in usePORDetails: ", args);
  const [data, setData] = useState(() => {
    if (
      !!args.params.workFlowId ||
      (args.params.porNumber && args.params.type)
    ) {
      return queryCache.getQueryData(["por-approval", args]);
    } else {
      return queryCache.getQueryData(["por-my-request", args]);
    }
  });

  const approvalQuery = useApprovalDetails({
    args,
    enabled:
      args.params.workFlowId || (args.params.porNumber && args.params.type),
    onSuccess: (data) => {
      console.log("data in approvalQuery: ", data);
      setData(data);
    },
  });
  // console.log({approvalQuery});
  const myRequestQuery = useRequestDetails({
    //need to check url to see if approvals or requests
    args,
    enabled: !!args.params.porNumber,
    onSuccess: (data) => {
      console.log("data in myRequestQuery: ", data);
      setData(data);
    },
  });
  // console.log({myRequestQuery});
  const details = useAsync({
    queries: {
      approvalQuery,
      myRequestQuery,
    },
  });
  console.log({ details, data });
  // console.log({data});
  return { ...details, data };
};

export const useAllTeamMembers = () => {
  const queryFn = async () => {
    return (await PORAPI.getAllTeamMembers()).data.reduce(
      (acc, el) => ({
        ...acc,
        [el.TeamId]: el,
      }),
      {}
    );
  };

  return useQuery("all-team-members", queryFn, {
    staleTime: 1000 * 60 * 60,
    retry: 1,
  });
};
