// Create the main myMSALObj instance
// configuration parameters are located at authConfig.js
import * as msal from "@azure/msal-browser";
import {
  msalConfig,
  loginRequest,
  tokenRequest,
  setUserAccount as showWelcomeMessage,
  setUsername,
  username,
} from "_shared/auth";

const myMSALObj = new msal.PublicClientApplication(msalConfig);

export function handleResponse(resp) {
  console.log("handleResponse", { resp });
  if (resp !== null) {
    setUsername(resp.account.username);
    showWelcomeMessage(resp.account);
    return resp;
  } else {
    /**
     * See here for more info on account retrieval:
     * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-common/docs/Accounts.md
     */
    const currentAccounts = myMSALObj.getAllAccounts();
    if (currentAccounts === null) {
      return;
    } else if (currentAccounts.length > 1) {
      // Add choose account code here

      console.log("Multiple accounts detected.");
    } else if (currentAccounts.length === 1) {
      console.log({ currentAccounts });
      setUsername(currentAccounts[0].username);
      showWelcomeMessage(currentAccounts[0]);
    }
  }
}

export function signIn() {
  console.log("called signIn", { loginRequest });
  myMSALObj.loginRedirect(loginRequest);
}

export function signOut(un) {
  // console.log("signout", { username });
  // const logoutRequest = {
  //   account: myMSALObj.getAccountByUsername(un),
  // };

  // myMSALObj.logout(logoutRequest);
  myMSALObj.logout();
}

export async function getTokenRedirect(request = loginRequest) {
  /**
   * See here for more info on account retrieval:
   * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-common/docs/Accounts.md
   */
  console.log({ loginRequest, username, request });
  request.account = myMSALObj.getAccountByUsername(username);
  return await myMSALObj
    .acquireTokenSilent({ ...request, forceRefresh: false })
    .catch((error) => {
      console.warn(
        "silent token acquisition fails. acquiring token using redirect"
      );
      if (error instanceof msal.InteractionRequiredAuthError) {
        // fallback to interaction when silent call fails
        return myMSALObj.acquireTokenRedirect(request);
      } else {
        console.warn(error);
        global.sessionStorage.clear();
        signIn();
      }
    });
}

export function seeProfile() {
  getTokenRedirect(loginRequest)
    .then((response) => {
      callMSGraph(graphConfig.graphMeEndpoint, response.accessToken, updateUI);
      profileButton.classList.add("d-none");
      mailButton.classList.remove("d-none");
    })
    .catch((error) => {
      console.error(error);
    });
}

export function readMail() {
  getTokenRedirect(tokenRequest)
    .then((response) => {
      callMSGraph(
        graphConfig.graphMailEndpoint,
        response.accessToken,
        updateUI
      );
    })
    .catch((error) => {
      console.error(error);
    });
}

export const initializeMsal = () =>
  myMSALObj
    .handleRedirectPromise()
    .then(handleResponse)
    .catch((err) => {
      console.error(err);
    });
