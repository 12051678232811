import React from "react";
import { Provider } from "react-redux";
import { NewsroomProvider } from "_shared/NewsroomContext";
import { AddEventToCalendarProvider } from "./component/AddEventToCalendar/context/AddEventToCalendarContext";
import { SystemOutageProvider } from "./component/SystemOutage/context/SystemOutageContext";
import store from "./store";
import { render } from "react-dom";
import App from "./component/App/App";
import "typeface-open-sans";
import "@fortawesome/fontawesome-pro/css/all.min.css";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import "./index.css";
import "./index.scss";
import "./_shared/spacing.scss";
import { getEnv } from "./utils/constants";
import { lightTheme } from "@applied-med/ui/dist/styles";
import setupLocatorUI from "@locator/runtime";

if (process.env.NODE_ENV === "development") {
  setupLocatorUI();
}

const unregisterServiceWorker = () => {
  if ("serviceWorker" in navigator) {
    navigator.serviceWorker.getRegistrations().then(function (registrations) {
      if (!registrations.length) {
        console.log("No serviceWorker registrations found.");
        return;
      }
      for (let registration of registrations) {
        registration.unregister().then(function (boolean) {
          console.log(
            boolean ? "Successfully unregistered" : "Failed to unregister",
            "ServiceWorkerRegistration\n" +
              (registration.installing
                ? "  .installing.scriptURL = " +
                  registration.installing.scriptURL +
                  "\n"
                : "") +
              (registration.waiting
                ? "  .waiting.scriptURL = " +
                  registration.waiting.scriptURL +
                  "\n"
                : "") +
              (registration.active
                ? "  .active.scriptURL = " +
                  registration.active.scriptURL +
                  "\n"
                : "") +
              "  .scope: " +
              registration.scope +
              "\n"
          );
          if (boolean) {
            window.location.reload();
          }
        });
      }
    });
  }
};

if (!getEnv("DEVMODE")) {
  unregisterServiceWorker();
}

const rootElement = document.getElementById("root");

const theme = createTheme({
  ...lightTheme,
  palette: {
    ...lightTheme.palette,
    primary: {
      main: "rgb(0, 149, 160)",
    },
  },
  overrides: {
    // Style sheet name ⚛️
    MuiTypography: {
      h6: {
        fontSize: "2 rem",
      },
    },
    MuiFormHelperText: {
      root: {
        fontSize: "1.5 rem",
      },
    },
    MuiInputBase: {
      root: {
        fontSize: "1.5 rem",
      },
    },
    MuiMenuItem: {
      root: {
        fontSize: "1.5 rem",
      },
    },
    MuiButton: {
      root: {
        fontSize: "1rem",
      },
    },
  },
});

render(
  <ThemeProvider theme={theme}>
    <Provider store={store}>
      <SystemOutageProvider>
        <NewsroomProvider>
          <AddEventToCalendarProvider>
              <App />
          </AddEventToCalendarProvider>
        </NewsroomProvider>
      </SystemOutageProvider>
    </Provider>
  </ThemeProvider>,
  rootElement
);
