export let accessableFeature = [];
export let isUnderMaintenance = [],
  appsUnderMaintenance = [];
import { useState, useEffect } from "react";
// import { user, getHasuraJwtToken } from "./auth";
import { graphql } from "./request";
import { pathOr, includes, findIndex, propEq } from "ramda";

export const getFeature = async (TeamID, CostcenterID) => {
  try {
    if ((TeamID, CostcenterID)) {
      const response = await graphql(
        `
          query featureToggle(
            $teamid: Int
            $costcenterid: Int
            $appname: String
            $origin: String
            $id: Int
          ) {
            feature(
              where: {
                _or: [
                  {
                    costcenter_feature_toggles: {
                      costcenter_id: { _eq: $costcenterid }
                      _and: { is_deleted: { _eq: false } }
                    }
                  }
                  {
                    user_feature_toggles: {
                      team_id: { _eq: $teamid }
                      _and: { is_deleted: { _eq: false } }
                    }
                  }
                ]
                is_active: { _eq: true }
                is_deleted: { _eq: false }
              }
            ) {
              name
            }
            arc_maintenance_page {
              is_under_maintenance
              page_name
              id
              origin
            }
            myapplied_maintenance_page {
              id
              app_name
              is_under_maintenance
              origin
            }
          }
        `,
        {
          teamid: parseInt(TeamID),
          costcenterid: parseInt(CostcenterID),
        }
      );

      accessableFeature = pathOr(false, ["data", "data", "feature"], response);
      isUnderMaintenance = pathOr(
        false,
        ["data", "data", "arc_maintenance_page"],
        response
      );
      appsUnderMaintenance = pathOr(
        false,
        ["data", "data", "myapplied_maintenance_page"],
        response
      );
      console.log(accessableFeature, appsUnderMaintenance);
    }
  } catch (e) {
    console.log("failed fetching feature");
  }
};

export const useFeature = (feature = "", app = "") => {
  const [bool, setBool] = useState(
    includes({ name: feature.toLowerCase() }, accessableFeature)
  );
  const [underMaintenance, setUnderMaintenance] = useState(false);

  useEffect(() => {
    const id = setInterval(() => {
      setBool(includes({ name: feature.toLowerCase() }, accessableFeature));

      if (app !== "") {
        const appIndex = findIndex(propEq("app_name", app))(
          appsUnderMaintenance
        );

        if (appIndex !== -1)
          setUnderMaintenance(
            appsUnderMaintenance?.[appIndex]?.["is_under_maintenance"]
          );
      }
    }, 1000 * 1);
    return () => {
      clearInterval(id);
    };
  }, [feature, accessableFeature, appsUnderMaintenance]);

  return app === "" ? bool : { feature: bool, underMaintenance };
};

export const useArcFeature = (origin = {}) => {
  const [underMaintenanceARC, setUnderMaintenanceARC] = useState();
  const env = isUnderMaintenance.filter((el) => el.origin === origin);
  useEffect(() => {
    const id = setInterval(() => {
      setUnderMaintenanceARC(env?.[0]?.["is_under_maintenance"]);
    }, 1000 * 1);
    return () => {
      clearInterval(id);
    };
  }, [isUnderMaintenance]);
  return { underMaintenanceARC };
};
