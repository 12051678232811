import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  useMemo,
} from "react";

const NewsroomContext = createContext();

export const useNewsroom = () => {
  return useContext(NewsroomContext);
};

export const NewsroomProvider = ({ children }) => {
  // for managing whether to automatically open the newsroom
  const [visitedNewsroom, setVisitedNewsroom] = useState(false);
  const [visitedNewsroomCount, setVisitedNewsroomCount] = useState(0);
  // newsroom states used by components outside of the newsroom
  // used to track the number of new announcements
  const [announcementsAndEvents, setAnnouncementsAndEvents] = useState([]);
  const [announcements, setAnnouncements] = useState([]);
  const [subscriptions, setSubscriptions] = useState([]);
  const [newAnnouncementCount, setNewAnnouncementCount] = useState(0);

  useEffect(() => {
    let newCount = 0;
    const subscribedAnnouncementTypes = subscriptions
      .filter(({ IsFav }) => IsFav)
      .map(({ announcement_type }) => announcement_type);

    for (let i = 0; i < announcementsAndEvents.length; ++i) {
      const card = announcementsAndEvents[i];
      if (
        card.AnnouncementType &&
        subscribedAnnouncementTypes.includes(card.AnnouncementTypeCode) &&
        card.isCardNew
      ) {
        newCount++;
      }
    }
    if (newCount !== newAnnouncementCount) {
      setNewAnnouncementCount(newCount);
    }
  }, [announcementsAndEvents, subscriptions]);

  return (
    <NewsroomContext.Provider
      value={{
        visitedNewsroom,
        setVisitedNewsroom,
        visitedNewsroomCount,
        setVisitedNewsroomCount,
        announcementsAndEvents,
        setAnnouncementsAndEvents,
        announcements,
        setAnnouncements,
        subscriptions,
        setSubscriptions,
        newAnnouncementCount,
        setNewAnnouncementCount,
      }}
    >
      {children}
    </NewsroomContext.Provider>
  );
};
