module.exports = {
  GRAPHQL_ENDPOINT:
    // "https://myappliedhasurajwtauth-consumption-plan.azurewebsites.net/api/hasurajwt",
    // "https://applied-fe-serverless.azurewebsites.net/api",
    "https://corp-feserverless-funcapp-prod-eastus2.azurewebsites.net/api",
  // "http://localhost:7071/api",
  GRAPHQL_WEBSOCKET_ENDPOINT:
    "wss://myappliedhasurajwtauth-consumption-plan.azurewebsites.net/api/hasurajwt",
  IT_ROOT_URL: "https://apistg.appliedmed.com/ITProjects",
  // DECISIONS_ENDPOINT: "https://decisionsqa1.appliedmed.com",
  DECISIONS_ENDPOINT: "https://bpmqa.appliedmedical.com",
  // DECISIONS2_ENDPOINT: "https://decisionsqa1.appliedmed.com",
  DECISIONS2_ENDPOINT: "https://decisionsqa3sa.appliedmed.com",
  // GRAPHQL_SECRET: process.env.GRAPHQL_SECRET,
  GENERIC_API: "https://apistg.appliedmed.com/DecisionsFiles",

  // FROM PACKAGE.JSON
  AUTHBASEURL: "https://api.appliedmed.com/AuthQA",
  // BASEURL: "https://api.appliedmedical.com/MyApplied",
  // BASEURL: "https://apistg.appliedmed.com/MyApplied",
  BASEURL: "https://my-api-stg.appliedmed.com/MyApplied",
  CONTENTURL: "https://webassets.appliedmedical.com/content",
  DEVMODE: true,
  ECOMMERCEBASEURL: "https://apistg.appliedmed.com/companystore",
  ISPREVIEW: false,
  // internal playground api
  // ITROOTURL: "https://internal-apiqa.appliedmed.com/ITProjects-Playground",
  ITROOTURL: "https://apistg.appliedmed.com/ITProjects-Playground",
  ITAPPROOTURL: "https://appstg.appliedmed.com/ITProjects",
  LMS_ROOT_URL: "https://lms-stg.appliedmed.com/LMS",
  LMS_TREE_URL: "https://lms-stg.appliedmed.com/LMSTREE",
  LMS_NONQS_URL: "https://lms-stg.appliedmed.com/LMS2",
  IT_MVC_BASE_URL: "http://appstg.appliedmed.com/ITProjects",
  IT_ARC_BASE_URL: "https://pmqa1.z13.web.core.windows.net",
  POR_BASE_URL: "https://app-gatewayproxy-qa-uswest2-002.azurewebsites.net/por",
  POR_FILE_DOWNLOAD:
    // "https://applied-fe-serverless.azurewebsites.net/api/por_attachment",
    "https://corp-feserverless-funcapp-prod-eastus2.azurewebsites.net/api/por_attachment",
  SYSTEM_TRACKER_URL: "https://bpmqa.appliedmedical.com",
  GDR_BLOB_URL: "https://atsappstorageqa.blob.core.windows.net",
  ARCURL: "https://appliedarcprodtest.z22.web.core.windows.net",
  SHAREPOINT_DRIVE_ID:
    "b!FsZQ2jwwB0icMs1_hhQEgNs4eWYw5wxFqyo4vr5Pt6w-I_sjPeHdQLeJlj-IJtiM",
};
