import axios from "axios";
import { z } from "zod";
import { pathOr, path, has, test } from "ramda";
import axiosRetry from "axios-retry";
import {
  user,
  // setRefreshToken,
  RefreshToken,
  setIsAuthenticated,
  updateMsalJWT,
  // updateGlobalJWT,
  acquireLegacyJwt,
  msalJwtToken,
  msalJwtIdToken,
  JwtToken,
  // updateMsalIDJWT,
  // msalJwtIDToken,
} from "./auth";
import { getTokenRedirect as acquireMsalToken } from "_shared/authRedirect";
import { getEnv } from "../utils/constants";
import gql from "nanographql";
import conf from "../../config/config";

const instance = axios.create({
  baseURL: getEnv("BASEURL"),
});

const notificationsInstance = axios.create({
  baseURL: "https://apistg.appliedmed.com/MyApplied",
});

let isRefreshing = false;
let refreshSubscribers = [];

const subscribeTokenRefresh = (cb) => {
  refreshSubscribers.push(cb);
};

export const onRefreshed = (tokens) => {
  refreshSubscribers.map((cb) => cb(tokens));
  refreshSubscribers = [];
};

const refreshJwtToken = async () => {
  console.log("intercept:attempting to refresh tokens");
  try {
    if (msalJwtToken) {
      const msalRespObj = await acquireMsalToken();
      console.log("intercept:accessful msal token refresh", { msalRespObj });
      updateMsalJWT(msalRespObj);
      await acquireLegacyJwt(msalRespObj);
    } else {
      const {
        data: { jwtToken, refreshToken },
      } = await axios({
        method: "post",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        url: `${getEnv("AUTHBASEURL")}/api/Refresh`,
        data: {
          jwtToken: JwtToken,
          refreshToken: RefreshToken,
        },
        transformRequest: [
          (data, headers) => {
            delete headers.common.Authorization;
            return JSON.stringify(data);
          },
        ],
      });
      setRefreshToken(refreshToken);
      updateGlobalJWT(jwtToken);
    }
    return { msalRespObj, JwtToken };
  } catch (e) {
    console.log("refreshJwtToken errored", e);
    setIsAuthenticated(false);
    window.location.assign("/login");
    return "";
  }
};

export const isGraphqlAuthError = ({ data }) => {
  return (
    pathOr("", ["extensions", "code"], pathOr("", ["errors"], data)[0]) ===
    "invalid-jwt"
  );
};

const isGraphqlError = (response) => {
  const hasErrors = has("errors");
  return (
    hasErrors(pathOr({}, ["data"], response)) ||
    hasErrors(pathOr({}, ["data", "data"], response))
  );
};

const usesMsalAccessToken = (url) =>
  test(/(por|companystore|decisions|bpm|azurewebsites)/, url);

const usesMsalIdToken = (url) => test(/applied-fe-serverless/, url);

const authQueueRetry = (request) =>
  new global.Promise((resolve) => {
    subscribeTokenRefresh(
      ({ JwtToken, msalRespObj: { msalJwtIdToken, msalJwtToken } }) => {
        if (usesMsalAccessToken(request.url)) {
          request.headers["Authorization"] = `Bearer ${msalJwtToken}`;
        } else if (usesMsalIdToken(request.url)) {
          request.headers["Authorization"] = `Bearer ${msalJwtIdToken}`;
        } else {
          request.headers["Authorization"] = `Bearer ${JwtToken}`;
        }
        resolve(axios(request));
      }
    );
  });

const queueRetry = (request) =>
  new global.Promise((resolve) => {
    const retryInstance = axios.create({});
    axiosRetry(retryInstance, {
      retryDelay: axiosRetry.exponentialDelay,
      retryCondition: axiosRetry.isNetworkOrIdempotentRequestError,
      retries: 1,
    });
    resolve(retryInstance(request));
  });

instance.interceptors.response.use(
  async function (response) {
    if (isGraphqlError(response)) {
      const graphqlError = new Error(response.data.errors[0].message);
      response.original_status = response.status;
      response.status = response.data.errors[0].extensions.code;
      graphqlError.response = response;
      return global.Promise.reject(graphqlError);
    }
    return response;
  },
  async function (errorResponse) {
    // console.log("REQUEST errorResponse:", errorResponse);
    const hasJwtToken =
      !!pathOr(false, ["config", "headers", "Authorization"], errorResponse) &&
      path(["config", "headers", "Authorization"], errorResponse) !==
        "Bearer Undefined";

    const originalRequest = path(["config"], errorResponse);
    const is401 = path(["response", "status"], errorResponse) === 401;
    const is500 = path(["response", "status"], errorResponse) === 500;

    console.log({ hasJwtToken, is401, is500 });

    if (
      is401 &&
      hasJwtToken &&
      RefreshToken &&
      //Temporary Solution until web api can get mofified
      !usesMsalAccessToken(originalRequest.url) &&
      originalRequest.url !== `${getEnv("AUTHBASEURL")}/api/Refresh`
    ) {
      console.log("intercept:refreshing", originalRequest.url);
      if (!isRefreshing) {
        isRefreshing = true;
        const tokens = await refreshJwtToken(errorResponse);
        onRefreshed(tokens);
        isRefreshing = false;
      }
      return authQueueRetry(originalRequest);
    } else if (is500) {
      console.log("intercept:retrying 500", originalRequest.url);
      return queueRetry(originalRequest);
    } else {
      console.log("intercept:is error", originalRequest.url);
      return global.Promise.reject(errorResponse);
    }
  }
);

export const get = async (props, schema) => {
  try {
    const response = await instance({
      method: "get",
      params: {
        isPreview: getEnv("ISPREVIEW"),
        ...props.params,
      },
      ...props,
    });
    if (schema) {
      const validatedData = schema.parse(response.data);
      response.data = validatedData;
      return response;
    } else {
      return response;
    }
  } catch (error) {
    // Only log production errors --> process.env.NODE_ENV === "production"
    if (process.env.NODE_ENV === "production") {
      const errorDetails = {
        teamId: user.TeamId ? Number(user.TeamId) : 0,
        message: error.message || "No error message.",
        stack: error.stack || "No error details provided.",
        httpRequest: {
          method: "GET",
          url: props.url,
          params: props.params || "No params provided.",
          headers: props.headers || "No headers provided.",
          data: props.data || "No data provided.",
        },
        httpResponse: {
          status:
            error.response?.status || "Server did not respond to the request.",
          statusText:
            error.response?.statusText ||
            "Server did not respond to the request.",
          data:
            error.response?.data || "Server did not respond to the request.",
        },
      };

      if (axios.isAxiosError(error)) {
        // console.error("HTTP request error:", error, props);
        errorDetails.type = "HTTP";
      } else if (error instanceof z.ZodError) {
        // console.error("Validation error:", error.issues, props);
        errorDetails.type = "VALIDATION";
        errorDetails.stack = error.issues;
      } else {
        // console.error("Unexpected error:", error, props);
        errorDetails.type = "MISC";
      }
      logErrorToHasura(errorDetails, createUniqueKey(props));
    }
    console.error("request get error:", error);
    throw error;
  }
};

export const decisionsGet = async (props, schema) => {
  const propsWithSessionId = {
    ...props,
    params: {
      ...props.params,
      SessionId: JwtToken,
    },
  };
  const response = await get(propsWithSessionId, schema);
  return response;
};

export const getForNotifications = (props) => {
  return notificationsInstance({
    method: "get",
    params: {
      isPreview: getEnv("ISPREVIEW"),
      ...props.params,
    },
    ...props,
  });
};

export const post = async (props) => {
  try {
    const response = instance({
      method: "post",
      ...props,
    });
    return response;
  } catch (error) {
    if (process.env.NODE_ENV === "production") {
      const errorDetails = {
        teamId: user.TeamId ? Number(user.TeamId) : 0,
        message: error.message || "No error message.",
        stack: error.stack || "No error details provided.",
        httpRequest: {
          method: "POST",
          url: props.url,
          params: props.params || "No params provided.",
          headers: props.headers || "No headers provided.",
          data: props.data || "No data provided.",
        },
        httpResponse: {
          status:
            error.response?.status || "Server did not respond to the request.",
          statusText:
            error.response?.statusText ||
            "Server did not respond to the request.",
          data:
            error.response?.data || "Server did not respond to the request.",
        },
      };

      if (axios.isAxiosError(error)) {
        // console.error("HTTP request error:", error, props);
        errorDetails.type = "HTTP";
      } else {
        // console.error("Unexpected error:", error, props);
        errorDetails.type = "MISC";
      }
      logErrorToHasura(errorDetails, createUniqueKey(props));
    }
    console.error("request post error:", error);
    throw error;
  }
};

export const decisionsPost = async (props) => {
  const propsWithSessionId = {
    ...props,
    params: {
      ...props.params,
      SessionId: JwtToken,
    },
  };
  const response = await post(propsWithSessionId);
  return response;
};

export const put = (props) =>
  instance({
    method: "put",
    ...props,
  });

export const patch = (props) =>
  instance({
    method: "patch",
    ...props,
  });

export const del = (props) =>
  instance({
    method: "delete",
    ...props,
  });

export const azureADget = (props) =>
  instance({
    ...props,
    params: {
      ...props.params,
      SessionId: msalJwtToken,
      isPreview: getEnv("ISPREVIEW"),
    },
    headers: {
      ...props.headers,
      Authorization: `Bearer ${msalJwtToken}`,
    },
  });

export const azureADpost = (props) =>
  instance({
    method: "post",
    ...props,
    params: {
      ...props.params,
      SessionId: msalJwtToken,
    },
    headers: {
      ...props.headers,
      Authorization: `Bearer ${msalJwtToken}`,
    },
  });

export const azureADput = (props) =>
  instance({
    method: "put",
    ...props,
    params: {
      ...props.params,
      SessionId: msalJwtToken,
    },
    headers: {
      ...props.headers,
      Authorization: `Bearer ${msalJwtToken}`,
    },
  });

export const azureADpatch = (props) =>
  instance({
    method: "patch",
    ...props,
    params: {
      ...props.params,
      SessionId: msalJwtToken,
    },
    headers: {
      ...props.headers,
      Authorization: `Bearer ${msalJwtToken}`,
    },
  });

export const azureADdel = (props) =>
  instance({
    method: "delete",
    ...props,
    params: {
      ...props.params,
      SessionId: msalJwtToken,
    },
    headers: {
      ...props.headers,
      Authorization: `Bearer ${msalJwtToken}`,
    },
  });

export const azureADIdGet = (props) =>
  instance({
    method: "get",
    ...props,
    params: {
      ...props.params,
    },
    headers: {
      ...props.headers,
      Authorization: `Bearer ${msalJwtIdToken}`,
    },
  });

export const azureADIdPost = (props) =>
  instance({
    method: "post",
    ...props,
    params: {
      ...props.params,
    },
    headers: {
      ...props.headers,
      Authorization: `Bearer ${msalJwtIdToken}`,
    },
  });

export const azureADIdPut = (props) =>
  instance({
    method: "put",
    ...props,
    params: {
      ...props.params,
    },
    headers: {
      ...props.headers,
      Authorization: `Bearer ${msalJwtIdToken}`,
    },
  });

export const azureADIdPatch = (props) =>
  instance({
    method: "patch",
    ...props,
    params: {
      ...props.params,
    },
    headers: {
      ...props.headers,
      Authorization: `Bearer ${msalJwtIdToken}`,
    },
  });

export const azureADIdDel = (props) =>
  instance({
    method: "delete",
    ...props,
    params: {
      ...props.params,
    },
    headers: {
      ...props.headers,
      Authorization: `Bearer ${msalJwtIdToken}`,
    },
  });

export default instance;

export const delay = (ms) =>
  new global.Promise((resolve) => setTimeout(resolve, ms));

export const graphql = async (ql, variables = {}) => {
  const query = JSON.parse(gql(ql)(variables));
  const response = await azureADIdPost({
    url: getEnv("GRAPHQL_ENDPOINT"), //changed graphql_endpoint to test on docker
    data: {
      query,
      devMode: false,
    },
  });
  return response;
};

/**
 * generates a unqiue key for a request appending FlowAlias to url if provided
 * @param {*} props
 * @returns
 */
export function createUniqueKey(props) {
  let key = props.url;
  if (props.params && typeof props.params.FlowAlias === "string") {
    key += "?FlowAlias=" + props.params.FlowAlias;
  }
  return key;
}

// rate limit errors logged to Hasura
const errorCounts = new Map();
const MAX_SAME_ERRORS_PER_INTERVAL = 3; // adjust this limit as needed
const RESET_INTERVAL = 5 * 60 * 1000; // 5 minutes

export async function logErrorToHasura(errorDetails, errorKey) {
  // error details
  const errorSchema = `mutation InsertErrorDetails($message: String!, $type: String!, $httpRequest: jsonb!, $httpResponse: jsonb!, $stack: String!, $teamId: Int!) {
    insert_myapplied_error_logging(objects: {error_message: $message, error_type: $type, http_request: $httpRequest, http_response: $httpResponse, stack_trace: $stack, user_id: $teamId}) {
      returning {
        id
        user_id
        error_type
        timestamp
      }
    }
  }`;

  const currentCount = errorCounts.get(errorKey) || 0;

  if (currentCount < MAX_SAME_ERRORS_PER_INTERVAL) {
    try {
      // log error
      const response = await graphql(errorSchema, errorDetails);
      console.log("Error log successful:", response);
    } catch (e) {
      console.error("Error log unsuccessful:", e);
    }
  } else {
    console.log("Rate limit reached for this error:", errorDetails);
  }
}

// Reset the error counts at defined interval
setInterval(() => {
  errorCounts.clear();
}, RESET_INTERVAL); // Clear every 5 minutes

export const newGraphql = async (ql, variables = {}) => {
  const query = JSON.parse(gql(ql)(variables));
  console.log("endpoint is", getEnv("NEW_GRAPHQL_ENDPOINT"));
  console.log("object", {
    // url: conf.GRAPHQL_ENDPOINT,
    url: getEnv("NEW_GRAPHQL_ENDPOINT"), //changed graphql_endpoint to test on docker
    data: {
      query,
      devMode: getEnv("DEVMODE"),
    },
  });
  const response = await azureADIdPost({
    // url: conf.GRAPHQL_ENDPOINT,
    url: getEnv("NEW_GRAPHQL_ENDPOINT"), //changed graphql_endpoint to test on docker
    data: {
      query,
      devMode: false,
    },
  });
  return response;
};

export const getWebAsset = (url) => {
  try {
    let newUrl = new URL(url);
    newUrl.searchParams.set("token", msalJwtIdToken);
    // console.log("new url href: ", newUrl.href);
    return newUrl.href;
  } catch (error) {
    console.log("getWebAsset error: ", error);
    return url;
  }
};
