import { createStore, applyMiddleware } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import { install } from "redux-loop";
import cache from "./middleware/cache";
import rootReducer from "./reducers/root.reducer";

const ENV = process.env.NODE_ENV;
const initialState = {};
// Disable cache in testing env
const middleware = ENV !== "test" ? [cache] : [];

const enhancer = composeWithDevTools(applyMiddleware(...middleware), install());

const store = createStore(rootReducer, initialState, enhancer);

export default store;
