import React, { useState, useEffect } from "react";
import logo from "_shared/images/delta.png";
import Navlinks from "../Navlinks/Navlinks";
import { Link } from "react-router-dom";
import "./mobileNav.scss";
import MobileCitySelector from "../CampusMaps/MobileCitySelector";
import BuildingModal from "../CampusMaps/BuildingModal/BuildingModal";
import { get } from "_shared/request";

const MobileNavBar = ({
  width,
  height,
  collapsed,
  setCollapsed,
  withBackground,
  user,
  isMobile,
  city,
  currentTab,
  setBuildingName,
}) => {
  const [map, setMap] = useState({});

  useEffect(() => {
    const getData = async () => {
      try {
        setMap({});
        const { data } = await get({
          url: `/campusmap/am${city}`,
        });
        setMap(data);
      } catch (e) {
        console.log(e);
      }
    };
    getData();
  }, [city]);

  return (
    <div
      // styleName={withBackground && collapsed ? "dark_container" : "container"}
      className={withBackground && collapsed ? "bg-[#383a3a]" : ""}
    >
      <div
        // styleName={
        //   withBackground && collapsed ? "dark_mode" : "inner_container"
        // }
        className={
          withBackground && collapsed
            ? "h-[75px] w-screen border-none bg-[#383a3a]"
            : "h-[70px] w-screen bg-black opacity-60"
        }
      />
      {!collapsed && (
        <Link to="/" onClick={() => setCollapsed(true)}>
          <img
            data-cy="applied-logo"
            // style={{ top: 15, left: 20, position: "absolute", zIndex: 2 }}
            className="logo absolute left-5 top-[15px] z-[2] h-[35px] w-auto animate-fade-in"
            // styleName="fadeInText imgLogo"
            alt="screen background"
            src={logo.src}
            srcSet={logo.srcSet}
          />
        </Link>
      )}
      {!collapsed && (
        <i
          data-cy="mobile-nav-burger"
          // styleName={"iconContainer fadeInText"}
          className="fal fa-times fa-2x absolute right-5 top-4 animate-fade-in cursor-pointer text-white"
          // style={{ top: 16, right: 20, cursor: "pointer" }}
          role="button"
          onKeyPress={() => {
            setCollapsed(true);
          }}
          onClick={() => {
            setCollapsed(true);
          }}
          tabIndex={0}
        />
      )}
      {!collapsed && (
        <div
          // styleName="sidenav_lg"
          className="absolute left-[90px] top-0 z-[4]"
        >
          <ul
            // styleName="sidenav_lg__items fadeInText"
            className={`absolute left-[-90px] top-[80px] w-screen animate-fade-in list-none p-0 text-[1.6rem] font-normal uppercase [&_a]:block [&_a]:py-[11px] [&_a]:pl-[23px] [&_a]:pr-0 [&_a]:text-white [&_li]:m-0 [&_li]:w-full ${
              collapsed && "hidden"
            }`}
            // style={{ display: collapsed ? "none" : "" }}
          >
            <Navlinks setCollapsed={setCollapsed} user={user} />
          </ul>
        </div>
      )}
      <div
        // styleName="sidenav-opacity"
        className={`${
          collapsed && "hidden"
        } z-[3] h-[calc(100vh-70px)] w-screen bg-black opacity-60 `}
      />
      {collapsed && (
        <i
          data-cy="mobile-nav-burger"
          // styleName={
          //   withBackground
          //     ? "light_icon_container fadeInText"
          //     : "iconContainer fadeInText"
          // }
          className={`
            ${
              withBackground
                ? "absolute animate-fade-in text-gray-shades-40"
                : "absolute animate-fade-in text-white"
            } ${
            withBackground ? "text-gray-shades-80" : "text-white"
          } fal fa-bars fa-align-left fa-2x left-[11px] top-[21px] cursor-pointer `}
          // className="fal fa-bars fa-align-left fa-2x"
          // style={{
          //   cursor: "pointer",
          //   top: 21,
          //   left: 11,
          //   color: withBackground ? "#c8cbcd" : "#fff",
          // }}
          role="button"
          onKeyPress={() => {
            setCollapsed(false);
          }}
          onClick={() => {
            setCollapsed(false);
          }}
          tabIndex={0}
        />
      )}
      <div
        // styleName="iconContainer"
        className={`absolute right-[11px] top-5 items-center justify-center text-white ${
          collapsed ? "flex" : "hidden"
        }`}
        // style={{
        //   top: 20,
        //   right: 11,
        //   jusitfyContent: "center",
        //   alignItems: "center",
        //   display: collapsed ? "flex" : "none",
        // }}
      >
        <div className="flex">
          <div className="pr-6">
            <MobileCitySelector
            height={height}
            title={city}
            width={width}
            isMobile={isMobile}
            currentTab={currentTab}
            />
          </div>
          <BuildingModal
            buildingData={map}
            width={width}
            height={height}
            isMobile={isMobile}
            setBuildingName={setBuildingName}
          />
        </div>
      </div>
    </div>
  );
};

export default MobileNavBar;
