// When specificed, this middlware call checks to see if the request is already cached
const cache =
  ({ dispatch, getState }) =>
  (next) =>
  (action) => {
    const { isCached, request } = action;

    // NORMAL ACTION: PASS IT ON
    if (typeof isCached !== "function") return next(action);

    if (!request || typeof request !== "object") {
      throw new Error("Expected request to be present and a plain object");
    }

    // CHECK CACHE
    if (isCached(getState())) {
      console.log(`${request.type} is cached!`); // eslint-disable-line no-console
      return;
    }

    dispatch(request);
  };

export default cache;
