import React from "react";
import Loading from "../../Loading/Loading";
import ErrorImg from "_shared/images/error/server-error.svg";
import { sumTotalPrice } from "../helpers/PORItem.helper";
import "./POROverview/POROverview.scss";

export const LoadingComponent = () => (
  <div
    style={{
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      height: "100%",
      overflow: "hidden",
    }}
  >
    <Loading centered />
  </div>
);

export const NoDataComponent = ({ variant, activeTab, ...restProps }) => {
  console.log("restProps:", restProps);
  return (
    <div
      // styleName="requests-empty-state"
      className="mx-0 mb-0 mt-20 flex flex-col items-center justify-center"
    >
      <p className="m-0 font-light leading-[23px] text-black">
        {variant === "POROverview"
          ? activeTab === "approvals"
            ? "No pending approvals yet."
            : "No requests"
          : "No Data"}
      </p>
    </div>
  );
};

export const ErrorComponent = () => (
  <div
    // styleName="error-container"
    className="mx-0 my-2 flex h-full w-full flex-col items-center justify-center"
  >
    <h4>Server Error</h4>
    <img className="mx-0 my-2 w-1/2" src={ErrorImg} alt="Error" />
    <h4>Please refresh the page, open a new tab, </h4>
    <h4>or contact IT Department</h4>
  </div>
);

export const convertToDate = (date) => {
  if (date.indexOf("/Date") !== -1) {
    return new Date(parseInt(date.match(/\(([^)]+)\)/)[1]));
  } else {
    const year = date.slice(0, 4);
    const month = date.slice(4, 6) - 1;
    const day = date.slice(6, 8);

    return new Date(year, month, day, 0, 0, 0, 0);
  }
};

export const formatDecision = (decision) => {
  const formatted = decision.toLowerCase();
  return formatted[0].toUpperCase() + formatted.slice(1);
};

export const formatName = (name) => {
  const [first, last] = name.split(" ");
  return `${first} ${last.slice(0, 1)}.`;
};

export const formatApprovalData = (data, teamMembersDict) => {
  return data.map((el) => {
    const { FirstName, LastName } = teamMembersDict[el.userID.match(/\d+/g)[0]];

    return {
      ...el,
      requesterName: formatName(`${FirstName} ${LastName}`),
    };
  });
};

export const getApproverNotes = (porType, comment, items, currency) => {
  const price = `Total Amount: ${sumTotalPrice(items)} ${currency}`;
  if (porType === "POR") {
    return comment ? `${comment} ${price}` : price;
  } else {
    return comment;
  }
};

const replaceLast = (find, replace, string) => {
  const lastIndex = string.lastIndexOf(find);

  if (lastIndex === -1) return string;

  const beginString = string.substring(0, lastIndex);
  const endString = string.substring(lastIndex + find.length);

  return beginString + replace + endString;
};

export const getNotes = (notes, items, currency) => {
  const price = `Total Amount: ${sumTotalPrice(items)} ${currency}`;

  return replaceLast(price, "", notes);
};
