import React, { useState } from "react";
import { useSystemOutage } from "./context/SystemOutageContext";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const ExclamationTriangleIcon = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth={1.5}
      {...props}
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126ZM12 15.75h.007v.008H12v-.008Z"
      />
    </svg>
  );
};

const XMarkIcon = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth={1.5}
      {...props}
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M6 18 18 6M6 6l12 12"
      />
    </svg>
  );
};

const ChevronDownIcon = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" {...props}>
      <path
        fillRule="evenodd"
        d="M5.22 8.22a.75.75 0 0 1 1.06 0L10 11.94l3.72-3.72a.75.75 0 1 1 1.06 1.06l-4.25 4.25a.75.75 0 0 1-1.06 0L5.22 9.28a.75.75 0 0 1 0-1.06Z"
        clipRule="evenodd"
      />
    </svg>
  );
};

function SystemOutageBanner({ bannerRef, paraRef, isOverflowing }) {
  const { setDisplayBanner, outageInfo } = useSystemOutage();
  const [isExpanded, setIsExpanded] = useState(false);
  return (
    <div
      style={{
        textShadow: "none",
      }}
      className="bg-[#373D43] p-4"
      ref={bannerRef}
    >
      <div className="flex items-center">
        <div className="flex-shrink-0">
          <ExclamationTriangleIcon className="flex h-9 w-9 items-center stroke-[#FFB526]" />
        </div>

        <div className="ml-3 whitespace-normal">
          <h3 className="text-sm font-semibold text-zinc-50">
            {outageInfo.title}
          </h3>
          <p
            className={classNames(
              isExpanded ? "" : "line-clamp-2",
              "mt-2 text-xs font-medium text-zinc-300"
            )}
            ref={paraRef}
          >
            {outageInfo.message}
          </p>
          {isOverflowing && (
            <button
              type="button"
              className="group mt-2 flex cursor-pointer items-center gap-x-1 rounded-md border-none bg-transparent p-0 text-zinc-50 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-zinc-50"
              onClick={() => setIsExpanded(!isExpanded)}
            >
              <span className="font-semibold">
                {isExpanded ? "Show less" : "Show more"}
              </span>
              <ChevronDownIcon
                className={classNames(
                  isExpanded ? "rotate-180" : "",
                  "h-5 w-5 transform fill-current transition-transform duration-300 ease-in-out"
                )}
              />
            </button>
          )}
        </div>
        <div className="ml-auto pl-3">
          <div className="-mx-1.5 -my-1.5">
            <button
              type="button"
              className="inline-flex cursor-pointer rounded-md border-none bg-[#373D43] p-1.5 text-zinc-50 hover:bg-[#4b5055] focus-visible:bg-[#4b5055] focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-zinc-50"
              onClick={() => {
                setDisplayBanner(false);
              }}
            >
              <span className="sr-only">Dismiss</span>
              <XMarkIcon className="flex h-6 w-6 items-center stroke-zinc-50" />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SystemOutageBanner;
