module.exports = {
  GRAPHQL_ENDPOINT:
    // "https://crp-fe-funcapp-prd-eastus2-2.azurewebsites.net/api", //new east us 2 func app
    // "http://localhost:7071/api",
    "https://crp-fe-funcapp-prd-eastus2-2-qa.azurewebsites.net/api", //QA slot
  NEW_GRAPHQL_ENDPOINT:
    // "https://corp-feserverless-funcapp-prod-westus3.azurewebsites.net/api",
    // "https://crp-fe-funcapp-prd-eastus2-2-qa.azurewebsites.net/api", //QA slot
    "https://crp-fe-funcapp-prd-eastus2-2.azurewebsites.net/api", //new east us 2 func app

  GRAPHQL_WEBSOCKET_ENDPOINT:
    "wss://myappliedhasurajwtauth-consumption-plan.azurewebsites.net/api/hasurajwt",
  IT_ROOT_URL: "https://apistg.appliedmed.com/ITProjects",
  // "https://my-api-stg.appliedmed.com/ITProjects",
  // DECISIONS_ENDPOINT: "https://decisionsqa1.appliedmed.com",
  DECISIONS_ENDPOINT: "https://bpmqa.appliedmedical.com",
  // DECISIONS2_ENDPOINT: "https://decisionsqa1.appliedmed.com",
  DECISIONS2_ENDPOINT: "https://bpmqa.appliedmedical.com",
  // GRAPHQL_SECRET: process.env.GRAPHQL_SECRET,
  GENERIC_API: "https://apistg.appliedmed.com/DecisionsFiles",
  // "https://my-api-stg.appliedmed.com/DecisionsFiles",

  // FROM PACKAGE.JSON
  //AUTHBASEURL: "https://api.appliedmed.com/AuthDev",
  AUTHBASEURL:
    // "https://apidev.appliedmed.com/Authdev",
  "https://api.appliedmed.com/AuthQA",
  // "https://my-api-stg.appliedmed.com/Auth",
  // BASEURL: "https://api.appliedmedical.com/MyApplied",
  BASEURL: "https://my-api-stg.appliedmed.com/MyAppliedV8",
    // "https://apistg.appliedmed.com/MyApplied",
    // "https://my-api-stg.appliedmed.com/MyApplied",
  CONTENTURL: "https://webassetsstg.appliedmedical.com/content",
  DEVMODE: false,
  ECOMMERCEBASEURL: "https://apistg.appliedmed.com/companystore",
  // "https://my-api-stg.appliedmed.com/companystore",
  ECOMMERCEBASEURL2: "https://apistg.appliedmed.com/AMStore",
  // "https://my-api-stg.appliedmed.com/AMStore",
  ISPREVIEW: false,
  ITROOTURL: "https://apistg.appliedmed.com/ITProjects",
  // "https://my-api-stg.appliedmed.com/ITProjects",
  ITAPPROOTURL: "https://appstg.appliedmed.com/ITProjects",
  // "https://my-api-stg.appliedmed.com/ITProjects",
  LMS_ROOT_URL: "https://lms-stg.appliedmed.com/LMS",
  // "https://my-api-stg.appliedmed.com/LMS",
  LMS_TREE_URL: "https://lms-stg.appliedmed.com/LMSTREE",
  // "https://my-api-stg.appliedmed.com/LMSTree",
  LMS_NONQS_URL: "https://lms-stg.appliedmed.com/LMS2",
  IT_MVC_BASE_URL: "http://appstg.appliedmed.com/ITProjects",
  IT_ARC_BASE_URL: "https://pmqa1.z13.web.core.windows.net",
  POR_BASE_URL: "https://apistg.appliedmed.com/SapGatewayProxy/por",
  // "https://my-api-stg.appliedmed.com/SapGatewayProxy/por",
  // "https://localhost:5001/por",//for testing the proxy code on localhost
  POR_FILE_DOWNLOAD:
    // "https://applied-fe-serverless.azurewebsites.net/api/por_attachment",
    "https://crp-fe-funcapp-prd-eastus2-2.azurewebsites.net/api/por_attachment", // new east us 2 func app
  // POR_BASE_URL: "https://localhost:5001/por", //for testing the proxy code on localhost
  SYSTEM_TRACKER_URL: "https://bpmqa.appliedmedical.com",
  GDR_BLOB_URL: "atsappstorageqa",
  ARCURL: "https://appliedarc1qa.z1.web.core.windows.net",
  SHAREPOINT_DRIVE_ID:
    "b!FsZQ2jwwB0icMs1_hhQEgNs4eWYw5wxFqyo4vr5Pt6zZ5Bp8BgRbQqo_albz4-3T",
};
