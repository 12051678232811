import React, { useState, useEffect } from "react";
import loadable from "@loadable/component";
import SearchBox from "../SearchBox/SearchBox";
import { useFeature } from "_shared/feature.js";
import DashboardIconWithBG from "_shared/images/icons/dashboard-gray.svg";
import DashboardIconWithoutBG from "_shared/images/icons/dashboard-white.svg";
import EditEndpointLight from "../../_shared/images/icons/edit-endpoint-white.svg";
import EditEndpointDark from "../../_shared/images/icons/edit-endpoint-dark.svg";
import { Typography } from "@material-ui/core";
import ReactTooltip from "react-tooltip";
import { getTotalPcfByRegion } from "../Notifications/Forms/FormAPI/Form.queries";
import { HOSTNAME_WHITE_LIST, getEnv } from "../../utils/constants";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { withToastManager } from "react-toast-notifications";
import { get } from "_shared/request";
import { isAuthenticated } from "_shared/auth";
import { useNotifications } from "../Notifications/Notifications.api";
import { JwtToken } from "_shared/auth";
import Styles from "./SearchBar.scss";
import PORAPI from "../Notifications/POR/PORAPI";
import { useTranslation } from "react-i18next";
import getAnnouncementsAndEvents, {
  getAnnouncementSubscriptions,
} from "../Newsroom/NewsroomAPI";
import { useNewsroom } from "_shared/NewsroomContext";
import { useSystemOutage } from "../SystemOutage/context/SystemOutageContext";
import { user } from "../../_shared/auth";

const Notifications = loadable(() =>
  import(/* webpackPrefetch: true */ "../Notifications/Notifications")
);

const Newsroom = loadable(() =>
  import(/* webpackPrefetch: true */ "../Newsroom/Newsroom")
);
const UserProfile = loadable(() =>
  import(/* webpackPrefetch: true */ "../UserProfile/UserProfile")
);

const EditEndpointModal = loadable(() =>
  import(/* webpackPrefetch: true */ "../EditEndpointModal/EditEndpointModal")
);

const FeatureToggle = loadable(() =>
  import(/* webpackPrefetch: true */ "../FeatureToggle/FeatureToggle")
);

const formatTotal = (num) => {
  // console.log('inside formatTotal: ', num);
  return num > 100 ? "99+" : num;
};

const SearchBar = ({
  searchPlaceHolder,
  handleSearch,
  className,
  withBackground = false,
  withSearch = false,
  handleSearchSubmit,
  noSearch = false,
  toastManager,
  dashboard = false,
  url,
  isMobile,
  renderSearchBox,
  Ecommerce,
  isDirectory,
  errorMessage,
  newsroom,
  handleCloseNewsroom,
  newsroomWidth,
}) => {
  const { TeamId, firstName, lastName } = user;
  const {
    announcementsAndEvents,
    setAnnouncementsAndEvents,
    setSubscriptions,
    newAnnouncementCount,
  } = useNewsroom();
  const [openPendingActions, setOpenPendingActions] = useState(false);
  const [openUserProfile, setOpenUserProfile] = useState(false);
  const [serviceAlert, setServiceAlert] = useState({});
  const [openEditModal, setOpenEditModal] = useState(false);
  const [isFeatureToggleOpen, setIsFeatureToggleOpen] = useState(false);
  const hasPORAccess = PORAPI.useHasPORAccess();
  const location = useLocation();
  const homePage = location.pathname === "/";
  const notifications = useNotifications({ refetchOnMount: false });
  const userProfile = useFeature("user profile");
  const featureToggle = useFeature("feature toggle");
  // const systemTracker = useFeature("system tracker");
  const { bannerHeight } = useSystemOutage();
  const newsroomFeature = true;
  const [systemTrackerBadgeColor, setSystemTrackerBadgeColor] =
    useState("Green");
  ///////////////////////////////////// PCF //////////////////////////////////////////////////
  // const isCerTeamMember = useFeature('Cer Form View');
  const [isCsTeamMember, setIsCsTeamMember] = useState(false);
  const isAMR = useFeature("Amr-cs-team");
  const isAME = useFeature("Ame-cs-team");
  const isAML = useFeature("Aml-cs-team");
  const isAuNz = useFeature("Am(au/nz)-cs-team");
  const [newsRoomOpen, setNewsRoomOpen] = useState(false);
  const [totalPCF, setTotalPCF] = useState(0);

  const navigate = useNavigate();

  useEffect(() => {
    // console.log({isAMR, isAME, isAML, isAuNz});
    if (isAMR === true || isAME === true || isAML === true || isAuNz === true) {
      let pcfTotal = getTotalPcfByRegion(isAMR, isAME, isAML, isAuNz).then(
        (result) => {
          console.log("result in SearchBar useEffect: ", result);
          setTotalPCF(result);
          return result;
        }
      );
      setIsCsTeamMember(true);
    }
  }, [isAMR, isAME, isAML, isAuNz]);

  ///////////////////////////////////// PCF //////////////////////////////////////////////////

  const { t } = useTranslation();

  //query announcements when user is opening from another tab
  useEffect(() => {
    const currentURL = window.location.href;
    const isInCorrectPath = currentURL.includes("/announcements");

    if (Object.keys(user).length > 0 && isInCorrectPath) {
      getAnnouncementsAndEvents(user.TeamId)
        .then((res) => {
          console.log("announcement res: ", res);
          setAnnouncementsAndEvents(res);
        })
        .catch((err) => {
          console.error(err);
        });
      getAnnouncementSubscriptions(user.TeamId)
        .then((res) => {
          console.log("subscription res: ", res);
          setSubscriptions(res);
        })
        .catch((err) => {
          console.error(err);
        });
    }
  }, [user]);

  useEffect(() => {
    const currentURL = location.pathname;

    if (currentURL.includes("/notifications")) {
      setOpenPendingActions(true);
    }
  }, [location]);

  useEffect(() => {
    const currentURL = location.pathname;
    if (currentURL.includes("?userprofile")) {
      setOpenUserProfile(true);
    }
  }, [location]);

  const handleNewsroomClick = () => {
    if (newsroom === true) {
      console.log("it is open");
      handleCloseNewsroom();
    } else {
      getAnnouncementsAndEvents(user.TeamId)
        .then((res) => {
          console.log("announcement res: ", res);
          setAnnouncementsAndEvents(res);
        })
        .catch((err) => {
          console.error(err);
        });
      getAnnouncementSubscriptions(user.TeamId)
        .then((res) => {
          console.log("subscription res: ", res);
          setSubscriptions(res);
        })
        .catch((err) => {
          console.error(err);
        });
      setNewsRoomOpen(true);
    }
  };

  // New Pending Actions Feature
  const openNotifications = () => {
    if (homePage) {
      navigate("/notifications");
    } else {
      setOpenPendingActions(!openPendingActions);
    }
  };

  const getTooltipTypography = (title) => {
    return (
      <Typography
        style={{
          fontSize: 12,
          color: "#ffffff",
          fontFamily: "Open Sans",
        }}
      >
        {title}
      </Typography>
    );
  };

  const clickHandlerModal = () => {
    toastManager.add(
      <>
        <p>{serviceAlert.created_on_formatted}</p>
        <p dangerouslySetInnerHTML={{ __html: serviceAlert.message }}></p>
      </>,
      {
        appearance: "warning",
        autoDismiss: true,
        pauseOnHover: true,
      }
    );
    setClickedServiceAlert(true);
  };
  // Local storage
  function useLocalState(localItem) {
    const [loc, setState] = useState(localStorage.getItem(localItem));
    function setLoc(newItem) {
      localStorage.setItem(localItem, newItem);
      setState(newItem);
    }
    return [loc, setLoc];
  }

  const [clickedServiceAlert, setClickedServiceAlert] =
    useLocalState("serviceAlertStatus");

  return (
    isAuthenticated && (
      <>
        <div
          // className={className}
          className={`${
            withBackground ? "text-[#a3a9ac]" : "text-white"
          } relative right-0  flex items-center [&_form]:m-0`}
          // styleName={`searchBarContainer ${withBackground ? "grey" : "white"}`}
        >
          {renderSearchBox
            ? renderSearchBox()
            : withSearch &&
              !noSearch && (
                <div
                  style={{ marginRight: 5 }}
                  className={`${isDirectory ? "relative" : ""}`}
                >
                  <SearchBox
                    searchPlaceHolder={searchPlaceHolder}
                    withBackground={withBackground}
                    handleSearchSubmit={handleSearchSubmit}
                    handleSearch={handleSearch}
                    Ecommerce={Ecommerce}
                    isDirectory={isDirectory}
                  />
                  {errorMessage && (
                    <div
                      className={`absolute pl-[25px] pt-[4px] ${
                        newsroom ? "top-[44px]" : ""
                      }`}
                    >
                      <p className="flex h-[26px] w-[159px] items-center justify-center rounded-[4px] bg-[#1C1F21] text-[12px] text-white">
                        {errorMessage}
                      </p>
                    </div>
                  )}
                </div>
              )}
          {isAuthenticated && serviceAlert.is_active ? (
            <div style={{ height: "24px" }}>
              <i
                // className="fal fa-exclamation-triangle"
                onClick={() => {
                  clickHandlerModal();
                }}
                // styleName={
                //   withBackground
                //     ? "search-bar-container__icon"
                //     : "search-bar-container-shadow__icon"
                // }
                className={`${
                  withBackground
                    ? "text-typography-50 opacity-75"
                    : "drop-shadow-custom"
                } fal fa-exclamation-triangle relative mx-3 my-0 text-[24px]  hover:cursor-pointer`}
                style={{
                  cursor: "pointer",
                  position: "relative",
                }}
              >
                {!clickedServiceAlert && (
                  <span className="badge service-alerts">&nbsp;</span>
                )}
              </i>
            </div>
          ) : (
            <div />
          )}
          {!dashboard && (
            <Link to="/dashboard/home">
              <img
                src={
                  withBackground ? DashboardIconWithBG : DashboardIconWithoutBG
                }
                alt="Go to Dashboard"
                // styleName={
                //   withBackground
                //     ? "search-bar-container__icon"
                //     : "search-bar-container-shadow__icon"
                // }
                className={`${
                  withBackground
                    ? "text-typography-50 opacity-75"
                    : "drop-shadow-custom"
                } mx-3 my-0 block`}
                // style={{
                //   display: "block",
                // }}
                data-tip
                data-for="dashboard"
              />
              <ReactTooltip id="dashboard" place="bottom" effect="solid">
                {getTooltipTypography("Dashboard")}
              </ReactTooltip>
            </Link>
          )}
          {HOSTNAME_WHITE_LIST.includes(window.location.hostname) && (
            <div>
              <img
                data-cy="edit-endpoint-btn"
                src={withBackground ? EditEndpointDark : EditEndpointLight}
                alt="Go to Edit Endpoint Modal"
                // styleName={
                //   withBackground
                //     ? "search-bar-container__icon"
                //     : "search-bar-container-shadow__icon"
                // }
                className={`${
                  withBackground
                    ? "text-typography-50 opacity-75"
                    : "drop-shadow-custom"
                } mx-3 my-0 block cursor-pointer`}
                onClick={() => setOpenEditModal(true)}
                // style={{
                //   display: "block",
                //   cursor: "pointer",
                // }}
                data-tip
                data-for="edit-endpoint"
              />
              <ReactTooltip id="edit-endpoint" place="bottom" effect="solid">
                {getTooltipTypography("Edit Endpoint")}
              </ReactTooltip>

                <EditEndpointModal
                  openEditModal={openEditModal}
                  setOpenEditModal={setOpenEditModal}
                />
              </div>
            )}

          {featureToggle && (
            <div style={{ height: "24px" }}>
              <i
                // className="fas fa-book"
                // styleName={
                //   withBackground
                //     ? "search-bar-container__icon"
                //     : "search-bar-container-shadow__icon"
                // }
                className={`${
                  withBackground
                    ? "text-typography-50 opacity-75"
                    : "drop-shadow-custom"
                } fas fa-book relative mx-3 my-0 text-[24px] hover:cursor-pointer`}
                onClick={() => setIsFeatureToggleOpen(true)}
                data-tip
                data-for="feature-toggle"
              />
              <ReactTooltip id="feature-toggle" place="bottom" effect="solid">
                {getTooltipTypography("Feature Toggle")}
              </ReactTooltip>
              <FeatureToggle
                isFeatureToggleOpen={isFeatureToggleOpen}
                setIsFeatureToggleOpen={setIsFeatureToggleOpen}
              />
            </div>
          )}
          {/* // Featured Announcement */}
          {newsroomFeature && (
            <>
              <div
                style={{ height: "24px" }}
                data-tip
                data-for="applied-newsroom"
              >
                <i
                  // className="fas fa-megaphone"
                  // styleName={
                  //   withBackground
                  //     ? "search-bar-container__icon"
                  //     : "search-bar-container-shadow__icon"
                  // }
                  className={`fas fa-megaphone relative mx-3 my-0 text-[24px] hover:cursor-pointer ${
                    withBackground
                      ? "text-typography-50 opacity-75 [text-shadow:none]"
                      : "drop-shadow-custom"
                  }`}
                  onClick={handleNewsroomClick}
                >
                  {newAnnouncementCount > 0 && (
                    <span className="badge-newsroom">
                      {newAnnouncementCount}
                    </span>
                  )}
                </i>
              </div>
              <ReactTooltip id="applied-newsroom" place="bottom" effect="solid">
                {getTooltipTypography("Applied Newsroom")}
              </ReactTooltip>
            </>
          )}
          {/* Featured Announcement End */}
          {/* Pending Actions */}
          <div style={{ height: "24px" }}>
            <i
              // className="fas fa-clipboard-list-check"
              // styleName={
              //   withBackground
              //     ? "search-bar-container__icon"
              //     : "search-bar-container-shadow__icon"
              // }
              className={`fas fa-clipboard-list-check relative mx-3 my-0 text-[24px] hover:cursor-pointer ${
                withBackground
                  ? "text-typography-50 opacity-75 [text-shadow:none]"
                  : "drop-shadow-custom"
              }`}
              onClick={openNotifications}
              data-cy="pending-actions"
              data-tip
              data-for="pending-actions"
            >
              {(notifications.data.totalPending > 0 || totalPCF > 0) &&
                (!notifications.isLoading || totalPCF > 0) && (
                  <span className="badge">
                    {formatTotal(totalPCF + notifications.data?.totalPending)}
                  </span>
                )}
            </i>
            <ReactTooltip id="pending-actions" place="bottom" effect="solid">
              {getTooltipTypography("Pending Actions")}
            </ReactTooltip>
            <Notifications
              {...{
                list: notifications.data.items,
                openPendingActions,
                setOpenPendingActions,
                url,
                TeamId,
                user,
                homePage,
                totalPCF,
                isCsTeamMember,
              }}
            />
          </div>
          {/* Pending Actions End  */}
          {firstName && lastName && (
            <>
              {userProfile ? (
                <>
                  <Link
                    to={`${location.pathname}?userprofile=home`}
                    onClick={() => {
                      setOpenUserProfile(true);
                    }}
                    className={`${
                      withBackground ? "" : "mx-3 my-0 drop-shadow-custom"
                    } mx-3 my-0 flex h-6 w-6 cursor-pointer items-center justify-center rounded-md bg-teal-tints-70 [&_span]:m-0 [&_span]:text-center [&_span]:text-[12px] [&_span]:font-normal [&_span]:text-white`}
                    data-tip
                    data-for={`COMMITID: ${COMMITID.substring(0, 8)}`}
                    // styleName={
                    //   withBackground
                    //     ? `settings-box  ${"user-profile"}`
                    //     : `settings-box search-bar-container-shadow__icon ${"user-profile"}`
                    // }
                  >
                    <span>{`${firstName[0]}${lastName[0]}`}</span>
                  </Link>
                </>
              ) : (
                <>
                  <p
                    // styleName={
                    //   withBackground
                    //     ? `settings-box  ${"user-profile"}`
                    //     : `settings-box search-bar-container-shadow__icon ${"user-profile"}`
                    // }
                    className={`${
                      withBackground ? "" : "mx-3 my-0 drop-shadow-custom"
                    } mx-3 my-0 flex h-6 w-6 cursor-pointer items-center justify-center rounded-md bg-teal-tints-70 [&_span]:m-0 [&_span]:text-center [&_span]:text-[12px] [&_span]:font-normal [&_span]:text-white`}
                    data-tip
                    data-for={`COMMITID: ${COMMITID.substring(0, 8)}`}
                  >
                    <span>{`${firstName[0]}${lastName[0]}`}</span>
                  </p>
                </>
              )}
              {/* UserProfile --> awaiting rebuild */}
              <UserProfile
                user={user}
                isMobile={isMobile}
                open={openUserProfile}
                onClose={setOpenUserProfile}
              />
            </>
          )}
        </div>
        {newsRoomOpen && (
          <>
            <div
              style={{
                overflow: "hidden",
                width: `${newsroomWidth}px`,
                height: `calc(100% - ${bannerHeight}px)`,
                top: `${bannerHeight}px`,
                right: "0",
                position: "fixed",
                zIndex: "5",
              }}
            >
              <Newsroom
                setNewsRoomOpen={setNewsRoomOpen}
                isMobile={isMobile}
                TeamId={TeamId}
                user={user}
                announcementsAndEvents={announcementsAndEvents}
                setAnnouncementsAndEvents={setAnnouncementsAndEvents}
              />
            </div>
          </>
        )}
      </>
    )
  );
};
export default withToastManager(SearchBar);
