import { useMutation } from "react-query";
import ITProjectsAPI from "../ITProjectsAPI";

export const useUpdateScoring = (config) => {
  return useMutation(ITProjectsAPI.updateScoring, { ...config });
};

export const useUpdateRequestStatus = (config) => {
  return useMutation(ITProjectsAPI.updateRequestStatus, { ...config });
};

export const useUpdateApproverActionLog = (config) => {
  return useMutation(ITProjectsAPI.updateApproverActionLog, { ...config });
};

export const useUpdateRequestNotes = (config) => {
  return useMutation(ITProjectsAPI.updateRequestNotes, { ...config });
};

export const useUpdateBusinessImpact = (config) => {
  return useMutation(ITProjectsAPI.updateBusinessImpact, { ...config });
};

export const useUpdateErrorLog = (config) => {
  return useMutation(ITProjectsAPI.updateErrorLog, { ...config });
};

export const useSubmitPendingActionForm = (config) => {
  return useMutation(ITProjectsAPI.submitPendingActionForm, { ...config });
};

export const useEditApprovalChain = (config) => {
  return useMutation(ITProjectsAPI.editApprovalChain, { ...config });
};

export const useEditApprovers = (config) => {
  return useMutation(ITProjectsAPI.editApprovers, { ...config });
};

export const useUpdateApproverChangeLogs = (config) => {
  return useMutation(ITProjectsAPI.updateApproverChangeLogs, { ...config });
};

export const useUpdateRequest = (config) => {
  return useMutation(ITProjectsAPI.updateRequest, { ...config });
};
