import { loop, Cmd } from "redux-loop";
import {
  API,
  fetchTaskSuccess,
  fetchTaskFailure,
  fetchTasksSuccess,
  fetchTasksFailure,
  createTaskSuccess,
  createTaskFailure,
  updateTaskSuccess,
  updateTaskFailure,
  updateTaskStatusSuccess,
  updateTaskStatusFailure,
} from "../actions/taskActions";
import {
  FETCH_TASK,
  FETCH_TASKS,
  CREATE_TASK,
  UPDATE_TASK,
  UPDATE_TASK_STATUS,
} from "../actions/types";

const initialState = {
  isLoadingTask: false,
  isLoadingTasks: false,
  isLoadingUpdate: false,
  isLoadingUpdateStatus: false,
  error: null,
  byId: {},
  allIds: [],
  filteredIds: [],
  currentId: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_TASK.REQUEST: {
      return loop(
        { ...state, isLoadingTask: true, error: null },
        Cmd.run(API.getTask, {
          successActionCreator: fetchTaskSuccess,
          failActionCreator: fetchTaskFailure,
          args: [action.payload],
        })
      );
    }
    case FETCH_TASKS.REQUEST:
      return loop(
        { ...state, isLoadingTasks: true, error: null },
        Cmd.run(API.getTaskSummaries, {
          successActionCreator: fetchTasksSuccess,
          failActionCreator: fetchTasksFailure,
          args: [action.payload],
        })
      );
    case CREATE_TASK.REQUEST:
      return loop(
        { ...state, isLoadingTask: true, error: null },
        Cmd.run(API.addTask, {
          successActionCreator: createTaskSuccess,
          failActionCreator: createTaskFailure,
          args: [action.payload],
        })
      );
    case UPDATE_TASK.REQUEST:
      return loop(
        { ...state, isLoadingUpdate: true, error: null },
        Cmd.run(API.updateTask, {
          successActionCreator: () => updateTaskSuccess(action.payload),
          failActionCreator: updateTaskFailure,
          args: [action.payload],
        })
      );
    case UPDATE_TASK_STATUS.REQUEST:
      return loop(
        { ...state, isLoadingUpdateStatus: true, error: null },
        Cmd.run(API.updateTaskStatus, {
          successActionCreator: () => updateTaskStatusSuccess(action.payload),
          failActionCreator: updateTaskStatusFailure,
          args: [action.payload],
        })
      );
    case FETCH_TASK.SUCCESS:
      return {
        ...state,
        isLoadingTask: false,
        error: null,
        byId: { ...state.byId, ...action.entities },
        allIds: [...new Set([...state.allIds, ...action.ids])],
        currentId: action.ids[0],
      };
    case FETCH_TASKS.SUCCESS:
      return {
        ...state,
        isLoadingTasks: false,
        error: null,
        byId: { ...state.byId, ...action.entities },
        allIds: [...new Set([...state.allIds, ...action.ids])],
        filteredIds: action.ids,
      };
    case CREATE_TASK.SUCCESS:
      return {
        ...state,
        isLoadingTask: false,
        error: null,
        byId: { ...state.byId, ...action.entities },
        allIds: [...new Set([...state.allIds, ...action.ids])],
        filteredIds: [...new Set([...state.filteredIds, ...action.ids])],
      };
    case UPDATE_TASK.SUCCESS:
      return {
        ...state,
        isLoadingUpdate: false,
        error: null,
        byId: { ...state.byId, ...action.entities },
        allIds: [...new Set([...state.allIds, ...action.ids])],
      };
    case UPDATE_TASK_STATUS.SUCCESS:
      return {
        ...state,
        isLoadingUpdateStatus: false,
        error: null,
        byId: { ...state.byId, ...action.entities },
        allIds: [...new Set([...state.allIds, ...action.ids])],
      };
    case FETCH_TASK.FAILURE:
    case FETCH_TASKS.FAILURE:
    case CREATE_TASK.FAILURE:
    case UPDATE_TASK.FAILURE:
    case UPDATE_TASK_STATUS.FAILURE:
      return {
        ...state,
        isLoadingTask: false,
        isLoadingTasks: false,
        isLoadingUpdate: false,
        isLoadingUpdateStatus: false,
        error: action.err,
        currentId: null,
      };
    default:
      return state;
  }
};

// SELECTORS
export const hasErrored = (state) => {
  return state.tasks.error !== null;
};

export const getIsLoading = (state) => {
  const {
    isLoadingTask,
    isLoadingTasks,
    isLoadingUpdate,
    isLoadingUpdateStatus,
  } = state.tasks;
  return (
    isLoadingTask || isLoadingTasks || isLoadingUpdate || isLoadingUpdateStatus
  );
};

export const getTask = (state, taskId) => {
  const id = taskId || state.tasks.currentId;
  return state.tasks.byId[id] || null;
};

export const getTasks = (state, key = "allIds") => {
  return state.tasks[key].map((id) => state.tasks.byId[id]);
};
