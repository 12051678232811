import React, { useEffect, useState} from "react";
import { useNewsroom } from "_shared/NewsroomContext";
import { Navigate, Outlet, useLocation, useParams } from "react-router-dom";
import { isAuthenticated, user } from "_shared/auth";
import { path, pathOr } from "ramda";

const ROUTEPATHENV = "applied-saved-route";

const matchedMap = (mapRoute) => {
  if (mapRoute && global.localStorage.getItem(ROUTEPATHENV)) {
    const citySaved = mapRoute.split("/")[2];
    const city = citySaved.replace("#", "")
    const cityRoute = global.localStorage.getItem(ROUTEPATHENV).split("/")[2]; 

    return city === cityRoute

  } else {
    return false
  }
}


const setRoute = (route) => {
  global.localStorage.setItem(ROUTEPATHENV, route);
};

export const getRedirectRoute = () => {
  let path;
  if (matchedMap(global.localStorage.getItem("mappedin-route"))) {
    path = global.localStorage.getItem("mappedin-route");
  } else {
    path = global.localStorage.getItem(ROUTEPATHENV);
  }
  return path;
};

const AuthorizedRoutes = () => {
  const {
    visitedNewsroom,
    setVisitedNewsroom,
    visitedNewsroomCount,
    setVisitedNewsroomCount,
  } = useNewsroom();

  const [loading, setLoading] = useState(true);

  // newsroom is currently not supported for browser widths less than 1024px
  const supportedBrowserWidth = window.innerWidth >= 1024;

  const search = pathOr("", ["location", "search"], location);

  const location = useLocation();

  const currentPath = location.pathname;
  // show newsroom if user is US and has not visited newsroom
  const showNewsroom = currentPath === "/" && user?.isUS && !visitedNewsroom;

  // if current browser width is not supported or users that did not navigate to homepage first, set visitedNewsroom to true so that newsroom is not shown by default on resize and subsequent visit to homepage
  // For non-US based users and users that did not navigate to homepage first, increment visitedNewsroomCount. Count is used to toggle on the transition animation when opening newsroom.
  useEffect(() => {
    if (
      (currentPath !== "/" && currentPath !== "/Newsroom") ||
      (showNewsroom && !supportedBrowserWidth) ||
      user?.isUS === false
    ) {
      setVisitedNewsroom(true);
      if (visitedNewsroomCount === 0) {
        setVisitedNewsroomCount(visitedNewsroomCount + 1);
      }
    }
  }, [
    currentPath,
    showNewsroom,
    supportedBrowserWidth,
    user?.isUS,
    visitedNewsroomCount,
  ]);

  useEffect(() => {
    setRoute(currentPath + search);
  }, [currentPath, isAuthenticated, search]);

  const hasMapsHash = () => {
    if (window.location.pathname.includes("/campusmap") && window.location.hash.length > 2) {
      global.localStorage.setItem("mappedin-route", window.location.pathname + window.location.hash)
    } else {
      global.localStorage.removeItem("mappedin-route")
    }
    return true
  }

useEffect(() => {

  //MappedIn preserving the hash for when the directions are shared

  hasMapsHash()

  setLoading(false);

}, [window.location.hash, window.location.href])

if (loading) {  
  return null
}

  return isAuthenticated ? (
    showNewsroom && supportedBrowserWidth ? (
      <Navigate to={"/Newsroom"} />
    ) : (
      <Outlet />
    )
  ) : (
    <Navigate
      state={{ from: pathOr("/", ["location"], location) }}
      to={"/login"}
    />
  );
};

export default AuthorizedRoutes;
