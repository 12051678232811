// import { msalJwtToken, user } from "_shared/auth";
import {
  get,
  azureADget,
  azureADIdGet,
  azureADpost,
  azureADIdPost,
  createUniqueKey,
  logErrorToHasura,
} from "_shared/request";
import { msalJwtToken, user } from "_shared/auth";
import { getEnv } from "../../../../utils/constants";
import axios from "axios";
import { z } from "zod";

const instance = axios.create({
  baseURL: getEnv("BASEURL"),
});

//azure AD post functions that dont use sessionID
const azureADpostPOR = async (props) => {
  try {
    const response = instance({
      method: "post",
      ...props,
      params: {
        ...props.params,
      },
      headers: {
        ...props.headers,
        Authorization: `Bearer ${msalJwtToken}`,
      },
    });
    return response;
  } catch (error) {
    if (process.env.NODE_ENV === "production") {
      const errorDetails = {
        teamId: user.teamId || 0,
        message: error.message || "No error message.",
        stack: error.stack || "No error details provided.",
        httpRequest: {
          method: "POST",
          url: props.url,
          params: props.params || "No params provided.",
          headers: props.headers || "No headers provided.",
          data: props.data || "No data provided.",
        },
        httpResponse: {
          status:
            error.response?.status || "Server did not respond to the request.",
          statusText:
            error.response?.statusText ||
            "Server did not respond to the request.",
          data:
            error.response?.data || "Server did not respond to the request.",
        },
      };

      if (axios.isAxiosError(error)) {
        errorDetails.type = "HTTP";
      } else {
        errorDetails.type = "MISC";
      }
      logErrorToHasura(errorDetails, createUniqueKey(props));
    }
    console.error("request post error:", error);
    throw error;
  }
};

//azure AD get functions that dont use sessionID
const azureADgetPOR = async (props, schema) => {
  try {
    const response = await instance({
      method: "get",
      ...props,
      params: {
        ...props.params,
      },
      headers: {
        ...props.headers,
        Authorization: `Bearer ${msalJwtToken}`,
      },
    });
    if (schema) {
      const validatedData = schema.parse(response.data);
      response.data = validatedData;
      return response;
    } else {
      return response;
    }
  } catch (error) {
    if (process.env.NODE_ENV === "production") {
      const errorDetails = {
        teamId: user.teamId || 0,
        message: error.message || "No error message.",
        stack: error.stack || "No error details provided.",
        httpRequest: {
          method: "GET",
          url: props.url,
          params: props.params || "No params provided.",
          headers: {
            ...props.headers,
            Authorization: `Bearer ${msalJwtToken}`,
          },
        },
        httpResponse: {
          status:
            error.response?.status || "Server did not respond to the request.",
          statusText:
            error.response?.statusText ||
            "Server did not respond to the request.",
          data:
            error.response?.data || "Server did not respond to the request.",
        },
      };

      if (axios.isAxiosError(error)) {
        errorDetails.type = "HTTP";
      } else if (error instanceof z.ZodError) {
        errorDetails.type = "VALIDATION";
        errorDetails.stack = error.issues;
      } else {
        errorDetails.type = "MISC";
      }
      logErrorToHasura(errorDetails, createUniqueKey(props));
    }
    console.error("azureADgetPOR request get error:", error);
    throw error;
  }
};

export const getApprovals = () => {
  // let porAuth = sessionStorage.getItem("por-authorized");
  // console.log("por auth: ", porAuth);
  // if (porAuth === "false") {
  //   return new Promise((resolve) => {
  //     //return promise object and skip dashboardsummary call
  //     resolve("cannot access POR");
  //   });
  // }

  /////////////testing new call //////////////////////////////
  // console.log("user in getApprovals: ", user);
  const testAccounts = {
    AH6051: "POR_TEST_US1",
    VLUCUT: "POR_TEST_US2",
    PGSTOTTEN: "POR_TEST_US3",
    RW13129: "POR_TEST_EU1",
    "POR_Test_EU1@appliedmed.com": "POR_TEST_EU1",
    POR_TEST_EU1: "POR_TEST_EU1",
    KHUUSSEN: "POR_TEST_EU2",
    POR_TEST_EU2: "POR_TEST_EU2",
    RVANDERMEER: "POR_TEST_EU3",
    POR_TEST_EU3: "POR_TEST_EU3",
  };

  const userid = Object.keys(testAccounts).includes(user.username)
    ? testAccounts[user.username]
    : user.username;
  console.log({ userid });

  if (typeof userid === "undefined") {
    throw new Error("User object has not loaded yet.");
  }

  return azureADpostPOR({
    url: `${getEnv("POR_BASE_URL")}/workitems/all`,
    params: {
      userid: userid,
      // userid: "POR_TEST_EU3",
      tzoffset: "UTC-07:00",
    },
  })
    .then((res) => {
      // console.log("POR RES: ", res);
      if (res.status === 200) {
        // console.log("resetting 401 count. . . .");
        sessionStorage.setItem("401count", 0);
        // console.log("new 401 count: ", sessionStorage.getItem("401count"));
      }
      // if (res.status === 500 || res.status === 400) {
      //   // sessionStorage.setItem("por-authorized", false);
      // }
      // if (res.status === 401) {
      //   //check 401 statuses; if 5 401's appear; cancel POR dashboard summary calls
      //   // console.log("por get approvals 401");
      //   // const current401count =
      //   //   parseInt(sessionStorage.getItem("401count")) || 0; //check current number of 401 occurences
      //   // const new401count = current401count + 1; //increment number
      //   // sessionStorage.setItem("401count", new401count);
      //   // if (new401count >= 30) {
      //     //change back to 5 when merging to master
      //     // console.log("setting por-authorized to false. . .");
      //     // sessionStorage.setItem("por-authorized", false);
      //   // }
      // }
      return res;
    })
    .catch((error) => {
      // Error 😨
      if (error.response) {
        /*
         * The request was made and the server responded with a
         * status code that falls out of the range of 2xx
         */
        if (error.response.status === 400 || error.response.status === 500) {
          // sessionStorage.setItem("por-authorized", false);
        }
        if (error.response.status === 401) {
          //check 401 statuses; if 5 401's appear; cancel POR dashboard summary calls
          const current401count =
            parseInt(sessionStorage.getItem("401count")) || 0; //check current number of 401 occurences
          // console.log({current401count});
          const new401count = current401count + 1; //increment number
          sessionStorage.setItem("401count", new401count);
          // if (new401count >= 15) {
          //change back to 5 when merging to master
          // sessionStorage.setItem("por-authorized", false);
          // }
        }
        console.log("por api error response: ", error.response);
        console.log("por api error data: ", error.response.data);
        console.log("por api error status: ", error.response.status);
        console.log("por api error headers: ", error.response.headers);
      } else if (error.request) {
        /*
         * The request was made but no response was received, `error.request`
         * is an instance of XMLHttpRequest in the browser and an instance
         * of http.ClientRequest in Node.js
         */
        console.log("por api error request: ".error.request);
      } else {
        // Something happened in setting up the request and triggered an Error
        console.log("Por api Error", error.message);
      }
      console.log("por api error config: ", error.config);
      return "cannot access POR";
    });

  /////////////testing new call //////////////////////////////

  // console.log({ newApprovals });
  // return newApprovals;
};

export const getRequests = ({ params = { type: "POR", version: "70" } }) => {
  //version is used by SAP and native apps to prevent legacy versions access, since new features wont be supported by older versions
  return azureADget({
    url: `${getEnv("POR_BASE_URL")}/purchaseorder/all`,
    params,
  });
};

export const getApprovalDetails = ({ params }) => {
  return azureADgetPOR({
    url: `${getEnv("POR_BASE_URL")}/purchaseorder/workitem`,
    params,
  });
  // return azureADgetPOR({
  //   url: `${getEnv("POR_BASE_URL")}/workitems/workitem`
  // })
};

export const getRequestDetails = ({ params }) => {
  return azureADget({
    url: `${getEnv("POR_BASE_URL")}/purchaseorder/entity`,
    params,
  });
};

export const getAttachmentDetails = ({ params }) => {
  return azureADget({
    url: `${getEnv("POR_BASE_URL")}/purchaseorder/attachment`,
    params,
  });
};

export const submitResponse = (data) => {
  let { incoterms1, incoterms2, companyCode, apprLevel } = data;
  if (incoterms1 !== "") {
    //nothing to do - pass-through
  } else {
    if (companyCode !== "4000") {
      //non-EU
      incoterms1 = "FOB";
    } else {
      if (apprLevel === "009") {
        incoterms1 = "DDP";
        incoterms2 = "Amersfoort";
      }
    }
  }

  const additions = {
    incoterms1,
    incoterms2,
  };

  return azureADpostPOR({
    url: `${getEnv("POR_BASE_URL")}/purchaseorder/save`,
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },

    data: JSON.stringify({ d: { ...data, ...additions } }),
  });
};

export const getAllTeamMembers = () => {
  return get({
    url: `${getEnv("LMS_ROOT_URL")}/TeamMember/All`,
  });
};

export const getAttachmentDownload = async (
  // token,
  // url ,
  porNumber,
  seqNumber
) => {
  console.log({ porNumber, seqNumber });
  const fileCredentials = await azureADget({
    url: `${getEnv("POR_BASE_URL")}/purchaseorder/downloadattachmentset`,
    params: {
      porNumber: porNumber,
      seqNumber: seqNumber,
    },
  }).then((response) => {
    console.log("attachment response: ", response);
    return response;
  });
  // const serverlessEndpoint = "http://localhost:64306";
  // const url = `${serverlessEndpoint}/api/por_attachment`;
  const serverlessProxyURL = getEnv("POR_FILE_DOWNLOAD");
  const fileURL = fileCredentials.data.url;
  const fileToken = fileCredentials.data.token;
  const fileStream = await azureADIdGet({
    url: serverlessProxyURL, //need to change to actual fe serverless endpoint after done testing
    params: {
      fileURL: fileURL,
      fileToken: fileToken,
    },
    responseType: "blob",
  }).then(
    (res) =>
      // ArrayBuffer(res)
      res
  );
  console.log("fileStream: ", fileStream);
  // return { url, fileURL, fileToken };
  return fileStream;
};
