module.exports = {
  DECISIONS2_ENDPOINT: "https://bpm.appliedmedical.com",
  GRAPHQL_ENDPOINT:
    // "https://myappliedhasurajwtauth-consumption-plan.azurewebsites.net/api/hasurajwt",
    // "https://applied-fe-serverless.azurewebsites.net/api",
    "https://crp-fe-funcapp-prd-eastus2-2.azurewebsites.net/api", //new east us 2 func app
  // "http://localhost:7071/api",
  NEW_GRAPHQL_ENDPOINT:
    // "https://corp-feserverless-funcapp-prod-westus3.azurewebsites.net/api",
    "https://crp-fe-funcapp-prd-eastus2-2.azurewebsites.net/api", //new east us 2 func app
  GRAPHQL_WEBSOCKET_ENDPOINT:
    "wss://myappliedhasurajwtauth-consumption-plan.azurewebsites.net/api/hasurajwt",
  // GRAPHQL_SECRET: process.env.GRAPHQL_SECRET,
  IT_ROOT_URL: "https://api.appliedmed.com/ITProjects",
  // "https://my-api.appliedmed.com/ITProjects",

  GENERIC_API: "https://api.appliedmed.com/DecisionsFiles",
  // "https://my-api.appliedmed.com/DecisionsFiles",

  // PACKAGE.JSON
  AUTHBASEURL: "https://api.appliedmed.com/Authdev",
  // "https://my-api.appliedmed.com/Auth",
  BASEURL: "https://my-api.appliedmed.com/MyAppliedV8",
  CONTENTURL: "https://webassets.appliedmedical.com/content",
  DECISIONS_ENDPOINT: "https://bpm.appliedmedical.com",
  DEVMODE: false,
  ECOMMERCEBASEURL: "https://api.appliedmed.com/companystore",
  // "https://my-api.appliedmed.com/companystore",
  ECOMMERCEBASEURL2: "https://api.appliedmed.com/AMStore",
  // "https://my-api.appliedmed.com/AMStore",
  ISPREVIEW: false,
  ITROOTURL: "https://api.appliedmed.com/ITProjects",
  // "https://my-api.appliedmed.com/ITProjects",
  ITAPPROOTURL: "https://app.appliedmed.com/ITProjects",
  // "https://my-api.appliedmed.com/ITProjects",

  // LMS_ROOT_URL: "https://lms.appliedmed.com/LMS",
  // LMS_TREE_URL: "https://my-api.appliedmed.com/LMSTree",
  LMS_ROOT_URL: "https://lms.appliedmed.com/LMS",
  LMS_TREE_URL: "https://lms.appliedmed.com/LMSTREE",
  LMS_NONQS_URL: "https://lms.appliedmed.com/LMS2",
  IT_MVC_BASE_URL: "http://app.appliedmed.com/ITProjects",
  // "https://my-api.appliedmed.com/ITProjects",
  IT_ARC_BASE_URL: "https://requests.appliedmedical.com",
  ARCURL: "https://requests.appliedmedical.com",
  POR_BASE_URL: "https://api.appliedmedical.com/SapGatewayProxy/por",
  // "https://my-api.appliedmed.com/SapGatewayProxy/por",
  POR_FILE_DOWNLOAD:
    "https://crp-fe-funcapp-prd-eastus2-2.azurewebsites.net/api/por_attachment", //new east us 2 func app
  // "https://applied-fe-serverless.azurewebsites.net/api/por_attachment",
  // "https://app-gatewayproxy-prd-uswest2-002.azurewebsites.net/por",
  SYSTEM_TRACKER_URL: "https://bpm.appliedmedical.com",
  GDR_BLOB_URL: "atsappstorageprod",
  SHAREPOINT_DRIVE_ID: 'b!FsZQ2jwwB0icMs1_hhQEgNs4eWYw5wxFqyo4vr5Pt6xEUNLlkp7hQKQx9vhKigfd',
};
