import { useCallback } from "react";
import { useQuery } from "react-query";
import GDRAPI from ".";

export const useApprovals = (config = {}) => {
  const queryFn = useCallback(
    async () =>
      (await GDRAPI.getApprovals()).data?.Done?.RequestResponseItems?.filter(
        ({ State }) => State === "Current"
      ) ?? [],
    []
  );

  return useQuery("gdr-approvals", queryFn, { staleTime: 1000 * 5, ...config });
};
