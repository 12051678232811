import React, { useState, useEffect } from "react";
import propTypes from "prop-types";
import { includes } from "ramda";
import { formatInTimeZone, format } from "date-fns-tz";
import Clock from "react-clock";

const formatString = "eee, MMM d h:mm:ss a zzz BBBB";

const genTime = (date, timeZone) => {
  const timeZoneTime = formatInTimeZone(date, timeZone, formatString).split(
    " "
  );
  return timeZoneTime;
};

const WorldClock = ({ collapsed, timeZone, analog = false }) => {
  const [clockString, setClock] = useState(genTime(new Date(), timeZone));

  useEffect(() => {
    const timer = setInterval(() => {
      setClock(genTime(new Date(), timeZone));
    }, 1000);
    return () => {
      clearInterval(timer);
    };
  }, [timeZone]);

  const changeBG = () => {
    const hour = parseInt(clockString[3].split(":")[0], 10);
    const flex = clockString.slice(7, 10).join(" ");
    if (
      (includes("morning", flex) && hour > 7 && hour !== 12) ||
      includes("afternoon", flex)
    ) {
      return "";
    } else {
      // return "black";
      return "black";
    }
  };

  const bgcolor = changeBG();

  return analog ? (
    <div style={{ position: "relative" }}>
      <div className="mx-0 mb-[-3px] mt-0 text-[1.1rem]">{`${clockString[0]} ${clockString[1]} ${clockString[2]}`}</div>
      <div className="inline-block text-[2.1rem]">
        {clockString[3].split(":").slice(0, 2).join(":")}
      </div>
      <div className="mx-0 ml-[3px] mt-0 inline-block text-[1.2rem]">
        {clockString[4]}
      </div>
      <div
        style={{
          position: "absolute",
          right: 0,
          top: -20,
        }}
      >
        <Clock
          value={clockString[3]}
          size={58}
          className={`${
            bgcolor === "black" &&
            "[&>div:last-of-type>div]:bg-red-600 [&>div:nth-of-type(1)]:bg-black [&>div:nth-of-type(1)_div]:bg-white [&>div:nth-of-type(2)>div]:bg-white [&>div:nth-of-type(3)>div]:bg-white "
          } rounded-[50px] bg-white`}
        />
      </div>
    </div>
  ) : collapsed ? (
    <>
      <div className="inline-block text-[1.6rem]">
        {clockString[3].split(":").slice(0, 2).join(":")}
      </div>
      <div className="mx-0 ml-[3px] mt-0 inline-block text-[1.2rem]">
        {clockString[4]}
      </div>
    </>
  ) : (
    <>
      <div
        className={`[div[class^="react-clock__face"]]:bg-black mx-0 ml-[-3px] mt-0 text-[1.1rem] `}
      >{`${clockString[0]} ${clockString[1]} ${clockString[2]}`}</div>
      <div className="inline-block text-[2.1rem] ">
        {clockString[3].split(":").slice(0, 2).join(":")}
      </div>
      <div className="mx-0 ml-[3px] mt-0 inline-block text-[1.2rem]">
        {clockString[4]}
      </div>
    </>
  );
};

WorldClock.propTypes = {
  timeZone: propTypes.string,
};

export default WorldClock;
