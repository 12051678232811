export const ToTitleCase = (str) => {
  return str.replace(/\w\S*/g, (txt) => {
    txt = txt.charAt(0) + txt.substr(1).toLowerCase();
    txt = txt.split("/");
    if (txt.length > 1) {
      txt[0] = txt[0].charAt(0).toUpperCase() + txt[0].substr(1).toLowerCase();
      txt[1] = txt[1].charAt(0).toUpperCase() + txt[1].substr(1).toLowerCase();
      txt = txt.join("/");
    }
    return txt;
  });
};

// From MDN Docs encodeURIComponent
export const fixedEncodeURIComponent = (str) => {
  return encodeURIComponent(str).replace(/[!'()*]/g, function (c) {
    return "%" + c.charCodeAt(0).toString(16).toUpperCase();
  });
};
