import { schema } from "normalizr";

export const moduleSchema = new schema.Entity(
  "modules",
  {},
  { idAttribute: "Id" }
);
// export const moduleSchema = new schema.Entity(
//   "modules",
//   {},
//   { idAttribute: "id" }
// );
export const moduleListSchema = [moduleSchema];

// USERS
const userSchema = new schema.Entity("users", {}, { idAttribute: "TeamId" });
const userListSchema = [userSchema];
const myUserSchema = new schema.Entity("users", {}, { idAttribute: "TeamId" });
const myUserListSchema = [myUserSchema];

// SOPS
const SOPSchema = new schema.Entity("SOPs", {}, { idAttribute: "DocumentId" });
const SOPListSchema = [SOPSchema];

// SESSIONS
const SessionSchema = new schema.Entity("sessions");
const SessionListSchema = [SessionSchema];

// TASKS
const taskSchema = new schema.Entity("tasks", {}, { idAttribute: "taskId" });
const TaskListSchema = [taskSchema];

export default {
  moduleSchema,
  moduleListSchema,
  userSchema,
  userListSchema,
  myUserSchema,
  myUserListSchema,
  SOPSchema,
  SOPListSchema,
  SessionSchema,
  SessionListSchema,
  taskSchema,
  TaskListSchema,
};
