module.exports = {
  DECISIONS_ENDPOINT: "https://decisionsdev1.appliedmed.com",
  // DECISIONS2_ENDPOINT: "https://bpmqa.appliedmedical.com/",
  DECISIONS2_ENDPOINT: "https://decisionsdev2.appliedmed.com",
  // DECISIONS2_ENDPOINT: "https://decisionsdev2.appliedmed.com",
  // DECISIONS_ENDPOINT: "https://decisions.appliedmed.com",
  // GRAPHQL_ENDPOINT:
  GRAPHQL_ENDPOINT:
    "https://crp-fe-funcapp-prd-eastus2-2.azurewebsites.net/api", //container 2 prod
  // "http://localhost:7071/api",
  // "https://corp-feserverless-funcapp-prod-eastus2-qa.azurewebsites.net/api", //container 2 on new tenant QA slot
  NEW_GRAPHQL_ENDPOINT:
    // "https://corp-feserverless-funcapp-prod-westus3.azurewebsites.net/api",
    "https://crp-fe-funcapp-prd-eastus2-2.azurewebsites.net/api", //container 2
  GRAPHQL_WEBSOCKET_ENDPOINT:
    "wss://myappliedhasurajwtauth-consumption-plan.azurewebsites.net/api/hasurajwt",
  IT_ROOT_URL: "https://apidev.appliedmed.com/ITProjects",
  // "https://my-api-dev.appliedmed.com/ITProjects",
  GENERIC_API: "https://apistg.appliedmed.com/DecisionsFiles",
  // "https://my-api-dev.appliedmed.com/DecisionFiles",
  // "https://my-api-stg.appliedmed.com/DecisionFiles",

  // FROM PACKAGE.JSON
  AUTHBASEURL: "https://apidev.appliedmed.com/Authdev",
  // "https://my-api-dev.appliedmed.com/Auth",
  // "https://api.appliedmed.com/AuthQA", //switch to QA when testing POR on localhost with test accounts

  // BASEURL: "https://apidev.appliedmed.com/MyApplied",
  // SWITCH TO THIS ENDPOINT IF NOT ON COMPANY NETWORK
  BASEURL: "https://my-api-dev.appliedmed.com/MyAppliedV8",
    // "https://api.appliedmedical.com/MyApplied",
    // "https://my-api-dev.appliedmed.com/MyApplied",
  //"https://apistg.appliedmed.com/MyApplied", //using only for por dev testing, take out after

  CONTENTURL: "http://webassetsdev.appliedmedical.com/content",
  DEVMODE: false,
  ECOMMERCEBASEURL: "https://apidev.appliedmed.com/companystore",
  // "https://my-api-dev.appliedmed.com/companystore",
  ECOMMERCEBASEURL2: "https://apidev.appliedmed.com/AMStore",
  // "https://my-api-dev.appliedmed.com/AMStore",
  ISPREVIEW: false,
  ITROOTURL: "https://apidev.appliedmed.com/ITProjects",
  // ITROOTURL: "https://internal-apidev.appliedmed.com/ITProjects",
  // "https://my-api-dev.appliedmed.com/ITProjects",
  ITAPPROOTURL: "https://appdev.appliedmed.com/ITProjects",
  // "https://my-api-dev.appliedmed.com/ITProjects",
  ARCURL: "https://appliedarc2qa.z1.web.core.windows.net",
  LMS_ROOT_URL: "https://lms-dev.appliedmed.com/LMS",
  // "https://my-api-dev.appliedmed.com/LMS",
  LMS_TREE_URL: "https://lms-dev.appliedmed.com/LMSTREE",
  LMS_NONQS_URL: "https://lms-dev.appliedmed.com/LMS2",
  // "https://my-api-dev.appliedmed.com/LMSTree",
  IT_MVC_BASE_URL: "http://appdev.appliedmed.com/ITProjects",
  IT_ARC_BASE_URL: "https://pmqa2.z13.web.core.windows.net",
  // POR_BASE_URL: "https://app-gatewayproxy-qa-uswest2-002.azurewebsites.net/por",
  POR_BASE_URL: "https://apidev.appliedmed.com/SapGatewayProxy/por",
  // "https://my-api-dev.appliedmed.com/SapGatewayProxy/por",
  // "https://localhost:5001/por", //for testing the proxy code on localhost
  POR_FILE_DOWNLOAD:
    // "https://applied-fe-serverless.azurewebsites.net/api/por_attachment",
    // "https://corp-feserverless-funcapp-prod-eastus2-qa.azurewebsites.net/api/por_attachment",
    "https://crp-fe-funcapp-prd-eastus2-2.azurewebsites.net/api/por_attachment",
  SYSTEM_TRACKER_URL: "https://decisionsdev2.appliedmed.com",
  GDR_BLOB_URL: "corpdecisonsdevwestus3",
  SHAREPOINT_DRIVE_ID:
    "b!FsZQ2jwwB0icMs1_hhQEgNs4eWYw5wxFqyo4vr5Pt6xo-dp13x5GQIYIa4_n-tv1",
};
